import { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { MainLayout } from "./MainLayout";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { ChevronRight, Pencil, Upload, X, Sparkles, Plus, ArrowUpRight, FileText, ChevronLeft, Trash, ChevronDown } from "lucide-react";
import { useNodeApi } from '../hooks/useNodeApi';
import { Citation, ExtractedValue, ExtractLogResponse, UserDocument, UserDocumentUploadRequest } from '../services/api';
import toast from 'react-hot-toast';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { withRequiredAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import { ScrollArea } from "./ui/scroll-area";
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { useNavigate, useLocation } from "react-router-dom";
import { RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import React from "react";
import { Switch } from "./ui/switch";
import { Label } from "./ui/label";

const DATE_FORMAT = "date";  // this is a built-in format: https://json-schema.org/understanding-json-schema/reference/string#built-in-formats
const CURRENCY_FORMAT = "x-fai-currency";  // this is a custom format that we use to identify currency fields

interface BaseField {
  name: string;
  type: string;
  description: string;
  format?: string;
}

interface Field extends BaseField {
  children?: BaseField[];
  isExpanded?: boolean;
}

const isValidField = (field: Field): field is Field => {
  return field.name.trim() !== '' &&
    field.type !== '';
};

const isValidChildField = (child: BaseField): child is BaseField => {
  return child.name.trim() !== '' &&
    child.type !== '';
};

interface SchemaProperty {
  type: string;
  description?: string;
  format?: string;
  properties?: SchemaProperties;
  items?: SchemaProperty;
}

interface SchemaProperties {
  [key: string]: SchemaProperty;
}

interface ProcessedSchema {
  title: string;
  type: string;
  properties: SchemaProperties;
}

const processFields = (fields: Field[], schemaTitle: string): ProcessedSchema => {
  return {
    title: schemaTitle,
    type: "object",
    properties: fields.reduce((acc: SchemaProperties, field) => {
      if (!field.name.trim()) {
        return acc;
      }

      if (field.children && field.children.length > 0) {
        const validChildren = field.children.filter(isValidChildField);

        if (validChildren.length > 0) {
          if (field.type === 'array') {
            acc[field.name] = {
              type: "array",
              description: field.description || '',
              items: {
                type: "object",
                properties: validChildren.reduce((childAcc: SchemaProperties, child) => {
                  childAcc[child.name] = {
                    type: child.type,
                    ...(child.description && { description: child.description })
                  };
                  return childAcc;
                }, {})
              }
            };
          } else {
            acc[field.name] = {
              type: "object",
              description: field.description || '',
              properties: validChildren.reduce((childAcc: SchemaProperties, child) => {
                childAcc[child.name] = {
                  type: child.type,
                  ...(child.description && { description: child.description })
                };
                if (child.format) {
                  childAcc[child.name].format = child.format;
                }
                return childAcc;
              }, {})
            };
          }
        }
      } else if (isValidField(field)) {
        acc[field.name] = {
          type: field.type,
          ...(field.description && { description: field.description })
        };
        if (field.format) {
          acc[field.name].format = field.format;
        }
      }

      return acc;
    }, {})
  };
};

const getUserVisibleFieldType = (field: Field) => {
  if (field.type === "number" || field.type === "boolean" || field.type === "object" || field.type === "array") {
    return field.type;
  }
  if (field.type === "string") {
    if (field.format === DATE_FORMAT) {
      return "date";
    } else if (field.format === CURRENCY_FORMAT) {
      return "currency";
    } else {
      return "string";
    }
  }
  return "";
};

interface ResultItemProps {
  title: string;
  type: string;
  description: string;
  value: string | number | { [key: string]: string } | null;
  notFound?: boolean;
  citations?: Array<{
    content: string;
    page: number;
  }>;
  isParent?: boolean;
  isChild?: boolean;
}

function ResultItem({ title, type, description, value, notFound, citations, isParent }: ResultItemProps) {
  const handleValueClick = () => {
    if (citations && citations.length > 0) {
      window.dispatchEvent(new CustomEvent('navigateToCitations', {
        detail: { citations }
      }));
    }
  };

  return (
    <div className={`bg-white rounded-lg border border-gray-200 p-4 mb-4 ${isParent ? 'bg-gray-50' : ''}`}>
      <div className="flex items-center justify-between mb-2">
        <div>
          <span className="font-semibold text-lg">{title}</span>
          <span className="ml-2 text-sm text-gray-500">{type}</span>
        </div>
        {notFound && (
          <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full flex items-center">
            <X className="w-3 h-3 mr-1" />
            not found
          </span>
        )}
      </div>
      <p className="text-sm text-gray-500 mb-2">{description}</p>
      {!isParent && (
        <div
          className={`bg-gray-50 rounded p-3 ${citations?.length ? 'cursor-pointer hover:bg-gray-100 transition-colors' : ''}`}
          onClick={handleValueClick}
        >
          {notFound ? (
            <span className="text-gray-400">-</span>
          ) : value !== null && typeof value === 'object' ? (
            Object.entries(value).map(([key, val]) => (
              <div key={key} className="mb-2 last:mb-0">
                <span className="text-sm text-gray-500">{key}</span>
                <p className="font-medium">{val}</p>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-between">
              <span className="font-medium">{value !== null ? value : '-'}</span>
              {citations && citations.length > 0 && (
                <FileText className="w-4 h-4 text-blue-600 ml-2" />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

interface AISchemaGeneratorProps {
  onGenerate: (fields: Array<Field>) => void;
  accessToken: string | null;
}

export function AISchemaGenerator({ onGenerate, accessToken }: AISchemaGeneratorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { generateSchemaFromPrompt } = useNodeApi(accessToken);

  const handleGenerate = async () => {
    try {
      setIsLoading(true);
      const jsonSchema = await generateSchemaFromPrompt(prompt);

      const schemaObj = JSON.parse(jsonSchema) as ParsedSchemaResponse;

      const fields = Object.entries(schemaObj.properties).map(([name, value]) => {
        if (value.type === "object") {
          return {
            name,
            type: value.type,
            description: value.description || '',
            children: Object.entries(value.properties || {}).map(([childName, childValue]) => ({
              name: childName,
              type: childValue.type,
              description: childValue.description || ''
            }))
          };
        } else if (value.type === "array") {
          if (value.items && value.items.type === "object") {
            return {
              name,
              type: value.type,
              description: value.description || '',
              children: Object.entries(value.items?.properties || {}).map(([childName, childValue]) => ({
                name: childName,
                type: childValue.type,
                description: childValue.description || ''
              }))
            }
          } else {
            return {
              name,
              type: "string",
              description: value.description || ''
            };
          }
        } else {
          return {
            name,
            type: value.type,
            description: value.description || ''
          };
        }
      });

      onGenerate(fields);
      setIsOpen(false);
      setPrompt('');
    } catch (error) {
      toast.error('Failed to generate schema');
      console.error('Schema generation error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="w-full mb-4 justify-center py-2"
        >
          <div className="flex items-center justify-center">
            <Sparkles className="h-4 w-4 mr-2 text-blue-600 fill-blue-600" />
            <span className="text-sm">Generate template with AI</span>
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[550px] [&>button]:hidden">
        <DialogHeader>
          <DialogTitle>Generate Schema with AI</DialogTitle>
          <DialogDescription>
            Create a prompt for the schema you want to create
          </DialogDescription>
        </DialogHeader>
        <div className="py-4">
          <div className="flex items-start space-x-8">
            <label htmlFor="schema-prompt" className="text-sm font-medium whitespace-nowrap">
              Schema prompt
            </label>
            <Textarea
              id="schema-prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              className="flex-grow h-24 resize-none focus:border-none"
              placeholder="Enter Schema prompt..."
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleGenerate}
            className={`
              bg-blue-100 text-blue-900 hover:bg-blue-200
              ${!prompt.trim() ? 'opacity-50 cursor-not-allowed' : ''}
            `}
            disabled={isLoading || !prompt.trim()}
          >
            {isLoading ? (
              <div className="flex items-center space-x-2">
                <div className="h-4 w-4 border-2 border-blue-600 border-blue-600 rounded-full animate-spin" />
                <span>Generate</span>
              </div>
            ) : (
              <>
                <ArrowUpRight className="h-4 w-4 mr-2" />
                Generate
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

interface TemplateBuildProps extends WithLoggedInAuthInfoProps {
  category: 'compare' | 'extract';
}


const TemplateBuildBase = ({
  accessToken,
  category,
}: TemplateBuildProps) => {
  const location = useLocation();
  const { schemaData, editMode } = location.state || {};

  useEffect(() => {
    if (editMode && schemaData) {
      setTemplateName(schemaData.name);

      try {
        const schemaObj = JSON.parse(schemaData.json_schema) as ProcessedSchema;
        const parsedFields = Object.entries(schemaObj.properties).map(([name, value]: [string, ProcessedSchema['properties'][string]]) => {
          if (value.type === "array" && value.items?.type === "object") {
            return {
              name,
              type: value.type,
              description: value.description || '',
              children: Object.entries(value.items.properties || {}).map(([childName, childValue]: [string, ProcessedSchema['properties'][string]]) => ({
                name: childName,
                type: childValue.type,
                description: childValue.description || ''
              }))
            };
          } else if (value.type === "object") {
            return {
              name,
              type: value.type,
              description: value.description || '',
              children: Object.entries(value.properties || {}).map(([childName, childValue]: [string, ProcessedSchema['properties'][string]]) => ({
                name: childName,
                type: childValue.type,
                description: childValue.description || ''
              }))
            };
          } else {
            return {
              name,
              type: value.type,
              description: value.description || ''
            };
          }
        });

        setFields(parsedFields);
      } catch (error) {
        console.error('Failed to parse schema JSON:', error);
        setFields([{ name: "", type: "string", description: "" }]);
        toast.error('Failed to load template fields');
      }

      if (schemaData.options?.outputAsGrid) {
        setShowOutputInGrid(schemaData.options.outputAsGrid);
      }
    }
  }, [editMode, schemaData]);

  const [templateName, setTemplateName] = useState("My template 1");
  const [fields, setFields] = useState<Field[]>([{ name: "", type: "string", description: "" }]);
  const [uploadedDocuments, setUploadedDocuments] = useState<UserDocument[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const childFieldRefs = useRef<Map<number, (HTMLInputElement | null)[]>>(new Map());
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();

  const [fileUploadError, setFileUploadError] = useState(false);
  const [saveTemplateError, setSaveTemplateError] = useState(false);
  const [runExtractionError, setRunExtractionError] = useState(false);

  const { uploadDocument, createTemplate, runExtraction, getExtractionResults, updateSchema } = useNodeApi(accessToken);

  const resetExtractionState = () => {
    setShowResults(false);
    setExtractionResults([]);
    setIsExtractionLoading(false);
  };

  const setFieldAccordingToUserVisibleFieldType = (index: number, type: string, isChild = false, childIndex?: number) => {
    const newFields = [...fields];

    if (isChild && childIndex !== undefined) {
      const child = newFields[index].children?.[childIndex];
      if (child) {
        if (type === "date") {
          child.type = "string";
          child.format = DATE_FORMAT;
        } else if (type === "currency") {
          child.type = "string";
          child.format = CURRENCY_FORMAT;
        } else {
          child.type = type;
          delete child.format;
        }
      }
    } else {
      const field = newFields[index];
      if (type === "date") {
        field.type = "string";
        field.format = DATE_FORMAT;
      } else if (type === "currency") {
        field.type = "string";
        field.format = CURRENCY_FORMAT;
      } else {
        field.type = type;
        delete field.format;
      }
    }

    setFields(newFields);
    resetExtractionState();
  };

  const handleFieldChange = (index: number, key: keyof Field, value: string) => {
    const newFields = [...fields];
    newFields[index] = {
      ...newFields[index],
      [key]: value
    };
    setFields(newFields);
    resetExtractionState();
  };

  const handleChildFieldChange = (parentIndex: number, childIndex: number, key: keyof Field, value: string) => {
    const newFields = [...fields];
    const children = newFields[parentIndex].children;

    if (children && children[childIndex]) {
      children[childIndex] = {
        ...children[childIndex],
        [key]: value
      };
      setFields(newFields);
      resetExtractionState();
    }
  };

  const addField = () => {
    setFields([...fields, { name: "", type: "string", description: "" }]);
    resetExtractionState();
  };

  const deleteField = (index: number) => {
    if (fields.length > 1) {
      const newFields = fields.filter((_, i) => i !== index);
      setFields(newFields);
      resetExtractionState();
    }
  };

  const addChildField = (parentIndex: number) => {
    setFields((prevFields) => {
      const newFields = [...prevFields];
      if (!newFields[parentIndex].children) {
        newFields[parentIndex].children = [];
      }

      newFields[parentIndex].children?.push({ name: "", type: "string", description: "" });
      newFields[parentIndex].isExpanded = true;

      if (!childFieldRefs.current.has(parentIndex)) {
        childFieldRefs.current.set(parentIndex, []);
      }

      childFieldRefs.current.get(parentIndex)?.push(null);

      return newFields;
    });
    setTimeout(() => {
      const childRefs = childFieldRefs.current.get(parentIndex);
      if (childRefs) {
        const lastIndex = childRefs.length - 1;
        const lastChildRef = childRefs[lastIndex];

        if (lastChildRef) {
          lastChildRef.focus();
        } else {
          setTimeout(() => {
            const retryLastChildRef = childRefs[lastIndex];
            retryLastChildRef?.focus();
          }, 0);
        }
      }
    }, 0);
  };

  const generateTemplateWithAI = (fields: Array<Field>) => {
    setFields(fields);
    resetExtractionState();
    toast.success("AI template generated successfully");
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileUploadError(false);
    const newFiles = event.target.files;
    if (newFiles && newFiles[0]) {
      const file = newFiles[0];
      if (file.type === 'application/pdf') {
        try {
          const uploadRequest: UserDocumentUploadRequest = {
            filename: file.name,
            blob_url: "",
            origin: "upload",
            category: "template",
            owner_uid: "",
            owner_oid: "",
            run_id: "",
          };

          const { user_document_id, sas_url } = await uploadDocument(file, uploadRequest);
          setUploadedDocuments(prev => [...prev, { _id: user_document_id, filename: file.name, blob_url: sas_url } as UserDocument]);
          setPdfUrl(sas_url);
        } catch (error) {
          setFileUploadError(true);
          console.error(`Failed to upload file ${file.name}:`, error);
          toast.error(`Failed to upload file ${file.name}`);
        }
      } else {
        setFileUploadError(true);
        toast.error("Please upload a PDF file");
        return;
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const [isOverwriteConfirmOpen, setIsOverwriteConfirmOpen] = useState(false);
  const [pendingSave, setPendingSave] = useState(false);

  const saveTemplate = async (name: string) => {
    setSaveTemplateError(false);
    try {
      const jsonSchema = processFields(fields, name);
      const hasValidContent = Object.keys(jsonSchema.properties).length > 0;

      if (!hasValidContent) {
        toast.error("Please add at least one valid field");
        return;
      }

      if (editMode && schemaData && name === schemaData.name) {
        setIsOverwriteConfirmOpen(true);
        setPendingSave(true);
        return;
      }

      await createTemplate(
        name,
        jsonSchema,
        category,
        { outputAsGrid: showOutputInGrid }
      );

    } catch (error) {
      setSaveTemplateError(true);
      console.error("Failed to save template:", error);
      toast.error("Failed to save template");
      throw error;
    }
  };

  const handleOverwriteConfirm = async () => {
    if (!pendingSave || !schemaData?._id) return;

    try {
      const jsonSchema = processFields(fields, tempTemplateName);

      await updateSchema(
        schemaData._id,
        tempTemplateName,
        jsonSchema,
        category,
        { outputAsGrid: showOutputInGrid },
        {
          owner_uid: schemaData.owner_uid,
          owner_oid: schemaData.owner_oid
        }
      );

      setIsOverwriteConfirmOpen(false);
      setIsSaveDialogOpen(false);
      setPendingSave(false);
      toast.success("Template saved successfully");
      navigate(categoryConfig[category].path);
    } catch (error) {
      setSaveTemplateError(true);
      console.error("Failed to update schema:", error);
      toast.error("Failed to update schema");
    }
  };

  const [extractionResults, setExtractionResults] = useState<ExtractLogResponse[]>([]);

  const [isExtractionLoading, setIsExtractionLoading] = useState(false);

  const handleRunExtraction = async () => {
    setRunExtractionError(false);
    setShowResults(false);
    if (!isTemplateValid) return;

    try {
      setIsExtractionLoading(true);

      if (uploadedDocuments.length === 0) {
        toast.error("Please upload at least one document.");
        return;
      }

      const jsonSchema = processFields(fields, templateName);

      const extractionPromises = uploadedDocuments.map(async (doc) => {
        try {
          const extractionId = await runExtraction(doc._id, JSON.stringify(jsonSchema));
          return { documentId: doc._id, extractionId };
        } catch (error) {
          throw new Error(`Extraction failed for document ${doc.filename}`);
        }
      });

      const extractionResults = await Promise.all(extractionPromises);
      toast.success("Extraction started");

      const pollInterval = 4000; // 4 seconds
      const maxAttempts = 30; // 2 minute total

      const pollForResults = async () => {
        let attempts = 0;
        const results = new Map();

        const poll = async () => {
          if (attempts >= maxAttempts) {
            throw new Error("Extraction timed out.");
          }

          const allComplete = await Promise.all(
            extractionResults.map(async ({ documentId, extractionId }) => {
              if (results.has(documentId)) return true;

              try {
                const result = await getExtractionResults(extractionId);

                if (result.status === 'completed') {
                  results.set(documentId, result);
                  return true;
                } else if (result.status === 'failed') {
                  throw new Error(`Extraction failed for document ${uploadedDocuments.find(d => d._id === documentId)?.filename}`);
                }
                return false;
              } catch (error) {
                console.error(`Failed to get results for document ${documentId}:`, error);
                throw error;
              }
            })
          );

          if (allComplete.every(Boolean)) {
            const completedResults = Array.from(results.values());
            setExtractionResults(completedResults);
            toast.success("Extraction complete");
            setShowResults(true);
          } else {
            attempts++;
            setTimeout(poll, pollInterval);
          }
        };

        await poll();
      };

      pollForResults();

    } catch (error) {
      setRunExtractionError(true);

      if (error instanceof Error) {
        console.error("Failed to run extraction:", error);
        toast.error(error.message || "Failed to run extraction");
      } else {
        console.error("Unexpected error:", error);
        toast.error("Failed to run extraction");
      }
    } finally {
      setIsExtractionLoading(false);
    }
  };

  const handleSaveAndRun = () => {
    if (!isTemplateValid) return;
    handleRunExtraction();
    setShowResults(true);
  };

  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const [activeFieldCitations, setActiveFieldCitations] = useState<Citation[]>([]);

  useEffect(() => {
    const handleNavigateToCitations = (event: CustomEvent<{ citations: Array<{ content: string; page: number }> }>) => {
      const fullCitations = event.detail.citations.map(citation => {
        const matchingCitation = extractionResults.flatMap(result =>
          Object.values(result.result.citations)
            .flat()
            .find(c => c.content === citation.content && c.bbox.page === citation.page)
        ).filter(Boolean)[0];

        return matchingCitation;
      }).filter(Boolean) as Citation[];

      setActiveFieldCitations(fullCitations);

      if (fullCitations.length > 0) {
        setCurrentHighlightIndex(0);
        jumpToPage(fullCitations[0].bbox.page - 1);
      }
    };

    window.addEventListener('navigateToCitations', handleNavigateToCitations as EventListener);
    return () => {
      window.removeEventListener('navigateToCitations', handleNavigateToCitations as EventListener);
    };
  }, [extractionResults, jumpToPage]);

  const highlights = useMemo(() => {
    if (!activeFieldCitations.length) return [];

    return activeFieldCitations.map(citation => ({
      pageIndex: citation.bbox.page - 1,
      left: citation.bbox.left * 100,
      top: citation.bbox.top * 100,
      height: citation.bbox.height * 100,
      width: citation.bbox.width * 100,
    })).sort((a, b) => a.pageIndex - b.pageIndex || a.top - b.top);
  }, [activeFieldCitations]);

  const renderHighlights = useCallback((props: RenderHighlightsProps) => {
    return (
      <div>
        {highlights
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area"
              style={{
                position: 'absolute',
                left: `${area.left}%`,
                top: `${area.top}%`,
                width: `${area.width}%`,
                height: `${area.height}%`,
                background: 'rgba(255, 255, 0, 0.4)',
                mixBlendMode: 'multiply',
                pointerEvents: 'none',
              }}
            />
          ))}
      </div>
    );
  }, [highlights]);

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const navigateHighlight = useCallback((direction: 'next' | 'prev') => {
    if (highlights.length === 0) return;
    const newIndex = direction === 'next'
      ? (currentHighlightIndex + 1) % highlights.length
      : (currentHighlightIndex - 1 + highlights.length) % highlights.length;

    setCurrentHighlightIndex(newIndex);
    jumpToPage(highlights[newIndex].pageIndex);
  }, [currentHighlightIndex, highlights, jumpToPage]);

  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const [tempTemplateName, setTempTemplateName] = useState(templateName);

  const handleSaveClick = () => {
    setTempTemplateName(templateName);
    setIsSaveDialogOpen(true);
  };

  const handleSaveConfirm = async () => {
    if (!tempTemplateName.trim()) return;
    setTemplateName(tempTemplateName);

    try {
      if (editMode && schemaData && tempTemplateName === schemaData.name) {
        setIsOverwriteConfirmOpen(true);
        setPendingSave(true);
        return;
      }

      await saveTemplate(tempTemplateName);
      setIsSaveDialogOpen(false);
      toast.success("Template saved successfully");
      navigate(categoryConfig[category].path);
    } catch (error) {
      console.error("Failed to save template:", error);
    }
  };

  const [isEditingName, setIsEditingName] = useState(false);

  const isTemplateValid = useMemo(() => {
    return fields.every(field => {
      if (field.children && field.children.length > 0) {
        return field.name.trim() !== '' && field.children.every(child =>
          child.name.trim() !== '' && child.type !== ''
        );
      }

      return field.name.trim() !== '' && field.type !== '';
    });
  }, [fields]);

  function ShimmerResults() {
    return (
      <div className="animate-pulse">
        <div className="h-7 w-48 bg-gray-200 rounded mb-2"></div>
        <div className="h-4 w-96 bg-gray-200 rounded mb-6"></div>

        {[1, 2, 3].map((i) => (
          <div key={i} className="bg-white rounded-lg border border-gray-200 p-4 mb-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <div className="h-5 w-32 bg-gray-200 rounded"></div>
                <div className="h-4 w-16 bg-gray-200 rounded"></div>
              </div>
            </div>
            <div className="h-4 w-3/4 bg-gray-200 rounded mb-2"></div>
            <div className="bg-gray-50 rounded p-3">
              <div className="h-5 w-1/2 bg-gray-200 rounded"></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  const categoryConfig = {
    compare: {
      title: 'Compare',
      path: '/compare',
    },
    extract: {
      title: 'Extract',
      path: '/extract',
    }
  };

  const [showOutputInGrid, setShowOutputInGrid] = useState(false);

  return (
    <MainLayout>
      <div className="flex-1 bg-gray-100">
        <div className="h-full flex flex-col">
          <div className="py-8 px-8">
            <div className="mx-auto max-w-6xl">
              <div className="flex items-center text-sm text-gray-500 mb-6">
                <span
                  className="hover:text-gray-700 cursor-pointer"
                  onClick={() => navigate(categoryConfig[category].path)}
                >
                  {categoryConfig[category].title}
                </span>
                <ChevronRight className="h-4 w-4 mx-2" />
                <span className="font-medium text-gray-900">
                  Create your own template
                </span>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  {isEditingName ? (
                    <Input
                      value={templateName}
                      onChange={(e) => setTemplateName(e.target.value.trim())}
                      className="text-xl font-bold border-none focus:ring-0 px-0
                               bg-transparent hover:bg-gray-50/50 rounded
                               transition-colors h-8 w-[250px]"
                      autoFocus
                      onBlur={() => {
                        if (!templateName.trim()) {
                          setTemplateName("My template 1");
                        }
                        setIsEditingName(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (!templateName.trim()) {
                            setTemplateName("My template 1");
                          }
                          setIsEditingName(false);
                        }
                      }}
                    />
                  ) : (
                    <h1 className="text-2xl font-bold">
                      {templateName}
                    </h1>
                  )}
                  <Pencil
                    className="h-4 w-4 cursor-pointer text-gray-700
                             hover:text-gray-900 transition-colors"
                    onClick={() => setIsEditingName(true)}
                  />
                </div>
                <Button
                  variant="default"
                  size="sm"
                  className="bg-black text-white hover:bg-gray-800"
                  onClick={handleSaveClick}
                >
                  Save template
                </Button>

                <Dialog open={isSaveDialogOpen} onOpenChange={setIsSaveDialogOpen}>
                  <DialogContent className="sm:max-w-[550px] overflow-hidden p-0 rounded-lg">
                    <DialogHeader className="flex-shrink-0 border-b border-gray-200 sticky top-0 bg-white z-10">
                      <DialogTitle className="text-2xl font-semibold p-4 px-6 pb-3">Save scheme</DialogTitle>
                    </DialogHeader>

                    <div className="py-4 px-6">
                      <p className="text-gray-500 mb-6">
                        First, add all the fields you want to compare within your uploaded document.
                      </p>

                      <div className="flex items-center space-x-8">
                        <label htmlFor="schema-name" className="text-sm font-medium whitespace-nowrap">
                          Schema name
                        </label>
                        <Input
                          id="schema-name"
                          value={tempTemplateName}
                          onChange={(e) => setTempTemplateName(e.target.value)}
                          className="flex-grow"
                          placeholder="My own template"
                        />
                      </div>
                    </div>

                    <DialogFooter className="flex justify-end space-x-2 p-4">
                      <Button
                        variant="outline"
                        onClick={() => setIsSaveDialogOpen(false)}
                      >
                        Back to edit
                      </Button>
                      <Button
                        variant="outline"
                        onClick={handleSaveConfirm}
                        className={`text-blue-600 border-blue-100 hover:text-blue-700 flex items-center bg-blue-50 hover:bg-blue-100 ${!tempTemplateName.trim() ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={!tempTemplateName.trim()}
                      >
                        <ArrowUpRight className="h-4 w-4 mr-2" />
                        Save
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>

                <Dialog open={isOverwriteConfirmOpen} onOpenChange={setIsOverwriteConfirmOpen}>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle>Overwrite Schema</DialogTitle>
                      <DialogDescription>
                        Are you sure you want to overwrite this schema? This action cannot be undone.
                      </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="flex gap-2 justify-end">
                      <Button
                        variant="outline"
                        onClick={() => {
                          setIsOverwriteConfirmOpen(false);
                          setPendingSave(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="destructive"
                        onClick={handleOverwriteConfirm}
                      >
                        Overwrite
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          </div>

          <div className="flex-1 overflow-hidden px-8 pb-6">
            <div className="mx-auto max-w-6xl h-full">
              <div className="flex flex-col md:flex-row gap-2 h-full">
                <div className="w-full md:w-1/2">
                  <ScrollArea className="h-full">
                    <div className="pr-4">
                      <div className="bg-white border border-gray-200 rounded-lg p-6">
                        <h2 className="text-lg font-semibold mb-2">Configure fields</h2>
                        <p className="text-sm text-gray-500 mb-4">
                          First, add all the fields you want to compare within your uploaded document.
                        </p>

                        <AISchemaGenerator
                          onGenerate={generateTemplateWithAI}
                          accessToken={accessToken}
                        />

                        <div className="bg-white rounded-md overflow-hidden mb-4 border border-gray-200">
                          <table className="w-full border-collapse">
                            <thead>
                              <tr className="bg-gray-100">
                                <th className="font-semibold text-xs text-left p-2.5 border-r border-gray-200">Name</th>
                                <th className="font-semibold text-xs text-left p-2.5 border-r border-gray-200">Type</th>
                                <th className="font-semibold text-xs text-left p-2.5">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map((field, index) => (
                                <React.Fragment key={index}>
                                  <tr className="border-t border-gray-200 group">
                                    <td className="p-2.5 border-r border-gray-200 relative">
                                      <div className="flex items-center">
                                        {field.children && field.children.length > 0 && (
                                          <button
                                            onClick={() => {
                                              const newFields = [...fields];
                                              newFields[index].isExpanded = !newFields[index].isExpanded;
                                              setFields(newFields);
                                            }}
                                            className="mr-2 text-gray-400 hover:text-gray-600"
                                          >
                                            {field.isExpanded ? (
                                              <ChevronDown className="h-4 w-4" />
                                            ) : (
                                              <ChevronRight className="h-4 w-4" />
                                            )}
                                          </button>
                                        )}
                                        <Input
                                          placeholder="Name"
                                          value={field.name}
                                          onChange={(e) => handleFieldChange(index, "name", e.target.value)}
                                          className="text-xs w-full border border-gray-300 focus:ring-0 h-7 px-2 py-1"
                                        />
                                        <div className="absolute right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                                          <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => addChildField(index)}
                                            className="h-7 w-7 rounded-md"
                                          >
                                            <Plus className="h-3 w-3 text-gray-400 hover:text-blue-500" />
                                          </Button>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="p-2.5 border-r border-gray-200">
                                      {!field.children?.length ? (
                                        <select
                                          value={getUserVisibleFieldType(field)}
                                          onChange={(e) => setFieldAccordingToUserVisibleFieldType(index, e.target.value)}
                                          className="w-full h-7 rounded-md border-none bg-white px-2 py-0 text-xs focus:ring-0"
                                        >
                                          <option value="string">string</option>
                                          <option value="number">number</option>
                                          <option value="boolean">boolean</option>
                                          <option value="date">date</option>
                                          <option value="currency">currency</option>
                                        </select>
                                      ) : (
                                        <select
                                          value={getUserVisibleFieldType(field)}
                                          onChange={(e) => setFieldAccordingToUserVisibleFieldType(index, e.target.value)}
                                          className="w-full h-7 rounded-md border-none bg-white px-2 py-0 text-xs focus:ring-0"
                                        >
                                          <option value="object">a single item</option>
                                          <option value="array">a list of items</option>
                                        </select>
                                      )}
                                    </td>
                                    <td className="p-2.5 relative">
                                      <Input
                                        placeholder="Description"
                                        value={field.description || ''}
                                        onChange={(e) => handleFieldChange(index, "description", e.target.value)}
                                        className="text-xs w-full border border-gray-300 focus:ring-0 h-7 px-2 py-1"
                                      />
                                      <div className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
                                        {fields.length > 1 && (
                                          <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => deleteField(index)}
                                            className="h-7 w-7 rounded-md"
                                          >
                                            <Trash className="h-3 w-3 text-gray-400 hover:text-red-500" />
                                          </Button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                  {field.children && field.isExpanded && field.children.map((child, childIndex) => (
                                    <tr key={`${index}-${childIndex}`} className="border-t border-gray-200 group bg-gray-50">
                                      <td className="p-2.5 border-r border-gray-200">
                                        <div className="flex items-center">
                                          <div className="w-6 border-l-2 border-gray-300 h-7 ml-4" />
                                          <Input
                                            ref={(el) => {
                                              const childRefs = childFieldRefs.current.get(index);
                                              if (childRefs) {
                                                childRefs[childIndex] = el;
                                              }
                                            }}
                                            placeholder="Name"
                                            value={child.name}
                                            onChange={(e) => handleChildFieldChange(index, childIndex, "name", e.target.value)}
                                            className="text-xs w-full border-none focus:ring-0 h-7 px-2 py-1 ml-2"
                                          />
                                        </div>
                                      </td>
                                      <td className="p-2.5 border-r border-gray-200">
                                        <select
                                          value={getUserVisibleFieldType(child)}
                                          onChange={(e) => setFieldAccordingToUserVisibleFieldType(index, e.target.value, true, childIndex)}
                                          className="w-full h-7 rounded-md border-none bg-white px-2 py-0 text-xs focus:ring-0"
                                        >
                                          <option value="string">string</option>
                                          <option value="number">number</option>
                                          <option value="boolean">boolean</option>
                                          <option value="date">date</option>
                                          <option value="currency">currency</option>
                                        </select>
                                      </td>
                                      <td className="p-2.5 relative">
                                        <Input
                                          placeholder="Description"
                                          value={child.description}
                                          onChange={(e) => handleChildFieldChange(index, childIndex, "description", e.target.value)}
                                          className="text-xs w-full border-none focus:ring-0 h-7 px-2 py-1"
                                        />
                                        <div className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
                                          <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => {
                                              const newFields = [...fields];
                                              const children = newFields[index].children;

                                              if (children) {
                                                newFields[index].children = children.filter((_, i) => i !== childIndex);
                                                setFields(newFields);
                                              }
                                            }}
                                            className="h-7 w-7 rounded-md"
                                          >
                                            <Trash className="h-3 w-3 text-gray-400 hover:text-red-500" />
                                          </Button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <Button
                          variant="outline"
                          size="sm"
                          className="w-full mt-4 justify-center py-2"
                          onClick={addField}
                        >
                          <div className="flex items-center justify-center">
                            <Plus className="h-4 w-4 mr-2 text-gray-400 fill-gray-400" />
                            <span className="text-md text-gray-400">Add more</span>
                          </div>
                        </Button>

                        <div className={`flex ${category === 'extract' ? 'justify-between' : 'justify-end'} mt-6`}>
                          {category === 'extract' && (
                            <div className="flex items-center space-x-2">
                              <Switch
                                id="show-grid"
                                checked={showOutputInGrid}
                                onCheckedChange={setShowOutputInGrid}
                              />
                              <Label htmlFor="show-grid">Show output in Grid</Label>
                            </div>
                          )}
                          <Button
                            variant="outline"
                            size="sm"
                            className={`
                              text-blue-600 bg-blue-50 hover:bg-blue-100 border-blue-200 py-2 px-4
                              ${!isTemplateValid ? 'opacity-50 cursor-not-allowed' : ''}
                            `}
                            onClick={handleSaveAndRun}
                            disabled={!isTemplateValid}
                          >
                            Run
                          </Button>
                        </div>
                      </div>
                      {!fileUploadError && !saveTemplateError && !runExtractionError && showResults && (
                        <div className="bg-white border border-gray-200 rounded-lg p-6 mt-6">
                          {(isExtractionLoading || extractionResults.length === 0) && <ShimmerResults />}

                          {!isExtractionLoading && extractionResults.length > 0 && (
                            <ResultsBlock
                              extractionResults={extractionResults}
                              fields={fields}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </ScrollArea>
                </div>

                <div className="w-full md:w-1/2">
                  {pdfUrl ? (
                    <div className="relative h-full bg-white rounded-lg">
                      <button
                        onClick={() => { setPdfUrl(null); resetExtractionState(); }}
                        className="absolute top-3 right-3 z-10 p-1 bg-black/10 rounded-full"
                        aria-label="Remove PDF"
                      >
                        <X className="h-5 w-5 text-white" />
                      </button>

                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <div className="h-full [&_.rpv-core__viewer]:!h-full [&_.rpv-core__viewer-container]:!h-full [&_.rpv-core__doc]:!p-0 [&_.rpv-core__doc-loading]:!hidden [&_.rpv-core__page-layer]:!bg-white">
                          <Viewer
                            fileUrl={pdfUrl}
                            plugins={[highlightPluginInstance, pageNavigationPluginInstance]}
                            defaultScale={SpecialZoomLevel.PageWidth}
                            initialPage={highlights.length > 0 ? highlights[0].pageIndex : 0}
                          />
                        </div>
                      </Worker>

                      {highlights.length > 0 && (
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center space-x-4 bg-white rounded-full shadow-lg px-4 py-2">
                          <button
                            onClick={() => navigateHighlight('prev')}
                            className="text-gray-700 hover:text-gray-900 focus:outline-none transition-colors"
                            aria-label="Previous highlight"
                          >
                            <ChevronLeft className="h-5 w-5" />
                          </button>
                          <div className="text-sm text-gray-700">
                            {currentHighlightIndex + 1} of {highlights.length}
                          </div>
                          <button
                            onClick={() => navigateHighlight('next')}
                            className="text-gray-700 hover:text-gray-900 focus:outline-none transition-colors"
                            aria-label="Next highlight"
                          >
                            <ChevronRight className="h-5 w-5" />
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="bg-white border border-gray-200 rounded-lg p-6 h-full">
                      <div className="h-full flex flex-col items-center justify-center">
                        <div className="mb-4">
                          <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                          </svg>
                        </div>
                        <p className="text-sm font-medium text-gray-900">Drag & drop file here, or click to select files</p>
                        <p className="mt-1 text-xs text-gray-500">Choose the file that can be your template base</p>
                        <input
                          type="file"
                          className="hidden"
                          onChange={handleFileUpload}
                          ref={fileInputRef}
                          accept=".pdf"
                        />
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => fileInputRef.current?.click()}
                          className="mt-4"
                        >
                          <Upload className="h-4 w-4 mr-2" />
                          Upload Files
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export const TemplateBuild = withRequiredAuthInfo(TemplateBuildBase);

interface ResultsBlockProps {
  extractionResults: ExtractLogResponse[];
  fields: Field[];
}

function ResultsBlock({ extractionResults, fields }: ResultsBlockProps) {
  const resultItems: ResultItemProps[] = fields.flatMap(field => {
    const sanitizedFieldName = field.name.trim();

    if (field.children && field.children.length > 0) {
      const parentItem: ResultItemProps = {
        title: sanitizedFieldName,
        type: field.type || 'object',
        description: field.description?.trim() || '',
        value: null,
        isParent: true
      };

      let childItems: ResultItemProps[] = [];
      if (field.type === 'array') {
        childItems = extractionResults.flatMap(result => {
          const arrayData = result.result.data[sanitizedFieldName] as Array<Record<string, ExtractedValue>> || [];
          const allResultItems: ResultItemProps[] = [];
          for (let i = 0; i < arrayData.length; i++) {
            const item = arrayData[i];
            if (!field.children) continue;
            const childItems = field.children.map(child => {
              const sanitizedChildName = child.name.trim();
              const value = item[sanitizedChildName];
              const citations: Citation[] = [];  // TODO: make use of citations;
              return {
                title: sanitizedChildName,
                type: getUserVisibleFieldType(child),
                description: child.description?.trim(),
                value: value,
                notFound: value === null,
                citations: citations.length > 0 ? citations : undefined,
                isChild: true
              } as ResultItemProps;
            });
            allResultItems.push(...childItems);
          }
          return allResultItems;
        });
      } else {
        childItems = field.children.map(child => {
          const values = extractionResults.map(result => {
            const parentData = result.result.data[sanitizedFieldName] as Record<string, ExtractedValue> || {};
            const value = parentData[child.name];
            const citationKey = `${sanitizedFieldName}.${child.name}`;
            const citations = result.result.citations[citationKey]?.map(citation => ({
              content: citation.content,
              page: citation.bbox.page
            })) || [];
            return { value, citations };
          });

          const combinedValue = values.reduce((acc: ExtractedValue | null, curr) => {
            if (curr.value === null || curr.value === undefined) return acc;
            return curr.value;
          }, null);

          const combinedCitations = values.flatMap(v => v.citations || []);

          return {
            title: child.name,
            type: getUserVisibleFieldType(child),
            description: child.description?.trim(),
            value: combinedValue,
            notFound: combinedValue === null,
            citations: combinedCitations.length > 0 ? combinedCitations : undefined,
            isChild: true
          } as ResultItemProps;
        });
      }

      return [parentItem, ...childItems];
    }

    const values = extractionResults.map(result => {
      const value = result.result.data[field.name];
      const citations = result.result.citations[field.name]?.map(citation => ({
        content: citation.content,
        page: citation.bbox.page
      })) || [];
      return { value, citations };
    });

    const combinedValue = values.reduce((acc: ExtractedValue | null, curr) => {
      if (curr.value === null || curr.value === undefined) return acc;
      return curr.value;
    }, null);

    const combinedCitations = values.flatMap(v => v.citations || []);

    return [{
      title: field.name,
      type: getUserVisibleFieldType(field),
      description: field.description?.trim(),
      value: combinedValue,
      notFound: combinedValue === null,
      citations: combinedCitations.length > 0 ? combinedCitations : undefined
    } as ResultItemProps];
  });

  return (
    <div>
      <h2 className="text-xl font-semibold mb-2">Extraction results</h2>
      <p className="text-sm text-gray-500 mb-6">
        Results extracted from 1 document
      </p>
      {resultItems.map((result, index) => (
        <div key={index} className={result.isChild ? 'ml-6' : ''}>
          <ResultItem {...result} />
        </div>
      ))}
    </div>
  );
}

interface ParsedSchemaResponse {
  properties: {
    [key: string]: SchemaProperty;
  };
}
