import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { RedirectToLogin, RequiredAuthProvider } from "@propelauth/react";

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    {process.env.REACT_APP_AUTH_URL ?
      <RequiredAuthProvider
        authUrl={process.env.REACT_APP_AUTH_URL}
        displayIfLoggedOut={
          <RedirectToLogin
            postLoginRedirectUrl={window.location.href}
          />
        }>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RequiredAuthProvider>
      :
      <p>REACT_APP_AUTH_URL is not set. Check .env files.</p>
    }
  </React.StrictMode>
);

reportWebVitals();
