import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { MainLayout } from '../MainLayout';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import { ChevronRight, Search } from 'lucide-react';
import { Input } from '../ui/input';
import { withRequiredAuthInfo } from '@propelauth/react';
import { useNodeApi } from '../../hooks/useNodeApi';
import { toast } from 'react-hot-toast';
import { PDFPreviewSlider } from '../compare/PDFPreviewSlider';
import { WorkflowStep } from '../../services/api';

interface GuidelineItem {
  question: string;
  title: string;
  acceptable_answer: string;
  full_bboxes: Array<{
    bbox: {
      left: number;
      top: number;
      width: number;
      height: number;
      page: number;
    };
    type: string;
    content: string;
    image_url: string | null;
  }>;
}

function GuidelinesDetailSkeleton({ workflowId }: { workflowId: string }) {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <div className="flex-1 overflow-auto min-h-screen bg-white py-2">
        <div className="mx-auto px-2">
          <div className="bg-white h-full overflow-y-auto">
            <div className="p-6">
              <div className="flex items-center text-md text-gray-500 mb-6">
                <span className="hover:text-gray-700 cursor-pointer" onClick={() => navigate(`/workflows/${workflowId}`)}>Workflow</span>
                <ChevronRight className="h-4 w-4 mx-2" />
                <span className="font-medium text-gray-900">Guidelines</span>
              </div>

              <div className="mb-4">
                <div className="relative">
                  <Input
                    type="text"
                    placeholder="Filter guidelines..."
                    className="pl-8 pr-4 py-1 w-48 h-9"
                    disabled
                  />
                  <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
              </div>

              <Table className="border-collapse border border-gray-200">
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-1/4 border-y border-gray-200">Title</TableHead>
                    <TableHead className="border border-gray-200">Guideline</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {[...Array(5)].map((_, index) => (
                    <TableRow key={index}>
                      <TableCell className="border-y border-gray-200">
                        <div className="h-4 bg-gray-200 rounded animate-pulse w-48" />
                      </TableCell>
                      <TableCell className="border border-gray-200">
                        <div className="space-y-2">
                          <div className="h-4 bg-gray-200 rounded animate-pulse w-full" />
                          <div className="h-4 bg-gray-200 rounded animate-pulse w-3/4" />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export const GuidelinesDetail = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  const navigate = useNavigate();
  const { executionId } = useParams<{ executionId: string }>();
  const [filterQuery, setFilterQuery] = useState("");
  const [workflowId, setWorkflowId] = useState<string>("");
  const [guidelines, setGuidelines] = useState<GuidelineItem[]>([]);
  const [guidelinesStep, setGuidelinesStep] = useState<WorkflowStep | null>(null);
  const { fetchWorkflowExecutionLog } = useNodeApi(accessToken);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [selectedGuideline, setSelectedGuideline] = useState<GuidelineItem | null>(null);
  const { getPdfUrl } = useNodeApi(accessToken);

  const filteredGuidelines = useMemo(() => {
    const query = filterQuery.toLowerCase();
    return guidelines.filter(item =>
      item.title.toLowerCase().includes(query) ||
      item.question.toLowerCase().includes(query) ||
      item.acceptable_answer.toLowerCase().includes(query)
    );
  }, [guidelines, filterQuery]);

  useEffect(() => {
    const fetchGuidelines = async () => {
      if (!executionId) {
        console.error('No execution ID provided');
        return;
      }

      try {
        const log = await fetchWorkflowExecutionLog(executionId);
        setWorkflowId(log.request.workflow_id);

        const step = log.result?.steps.find(step =>
          step.step_type === 'extract_guidelines'
        );

        if (!step?.output?.items) {
          console.error('Missing guidelines items in response');
          setGuidelines([]);
          return;
        }

        setGuidelinesStep(step);
        setGuidelines(step.output.items);
      } catch (error) {
        console.error('Failed to fetch guidelines:', error);
        toast.error('Failed to load guidelines');
      } finally {
        setIsLoading(false);
      }
    };

    fetchGuidelines();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!executionId) {
    return <Navigate to="/" replace />;
  }

  if (isLoading) {
    return <GuidelinesDetailSkeleton workflowId={workflowId} />;
  }

  const handleRowClick = async (item: GuidelineItem) => {
    if (!item.full_bboxes?.length) return;

    try {
      const documentId = guidelinesStep?.input.guideline?.user_document_id;
      if (!documentId) {
        console.error('Document ID not found');
        return;
      }

      if (!currentPdfUrl) {
        const url = await getPdfUrl(documentId);
        setCurrentPdfUrl(url);
      }

      setSelectedGuideline(item);
      setIsSliderOpen(true);
    } catch (error) {
      console.error('Failed to fetch PDF URL:', error);
      toast.error('Failed to load PDF preview');
    }
  };

  return (
    <MainLayout>
      <div className="flex-1 overflow-auto min-h-screen bg-white py-2">
        <div className="mx-auto px-2">
          <div className="bg-white h-full overflow-y-auto">
            <div className="p-6">
              <div className="flex items-center text-md text-gray-500 mb-6">
                <span className="hover:text-gray-700 cursor-pointer" onClick={() => navigate(`/workflow-execution/${executionId}`)}>
                  Steps
                </span>
                <ChevronRight className="h-4 w-4 mx-2" />
                <span className="font-medium text-gray-900">Guidelines</span>
              </div>

              <div className="mb-4">
                <div className="relative">
                  <Input
                    type="text"
                    placeholder="Filter guidelines..."
                    className="pl-8 pr-4 py-1 w-48 h-9"
                    value={filterQuery}
                    onChange={(e) => setFilterQuery(e.target.value)}
                  />
                  <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
              </div>

              <Table className="border-collapse border border-gray-200">
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-1/4 border-y border-gray-200">Title</TableHead>
                    <TableHead className="border border-gray-200">Question</TableHead>
                    <TableHead className="border border-gray-200">Acceptable Answer</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredGuidelines.map((item, index) => (
                    <TableRow
                      key={index}
                      className={item.full_bboxes?.length ? 'cursor-pointer hover:bg-gray-50' : ''}
                      onClick={() => handleRowClick(item)}
                    >
                      <TableCell className="border-y border-gray-200 font-medium">
                        {item.title}
                      </TableCell>
                      <TableCell className="border border-gray-200 whitespace-pre-wrap">
                        {item.question}
                      </TableCell>
                      <TableCell className="border border-gray-200 whitespace-pre-wrap">
                        {item.acceptable_answer}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <PDFPreviewSlider
                isOpen={isSliderOpen}
                onClose={() => {
                  setIsSliderOpen(false);
                  setSelectedGuideline(null);
                }}
                pdfUrl={currentPdfUrl}
                citations={selectedGuideline?.full_bboxes?.map(bbox => ({
                  citation: {
                    bbox: bbox.bbox,
                    type: bbox.type,
                    content: bbox.content,
                    image_url: bbox.image_url
                  },
                  state: 'match'
                })) ?? null}
                isLoading={false}
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
});
