export const POLLING_CONFIG = {
    INITIAL_INTERVAL: 5000,
    MAX_INTERVAL: 180000,
    BACKOFF_MULTIPLIER: 1.5,
    MAX_RETRIES: 3,
    ERROR_MESSAGES: {
      UNAUTHORIZED: "Session expired. Please refresh the page.",
      SERVER_ERROR: "Connection error. Retrying...",
      GENERIC_ERROR: "An error occurred. Please refresh the page.",
      UNEXPECTED_ERROR: "An unexpected error occurred"
    }
  } as const;
