import { Button } from "./ui/button";
import { FileText, Sparkles, PhoneCall, ChartColumnStacked, Table2, Blocks, LogIn, LogOut, MoreVertical, SearchCheck, PanelLeft, FileOutput } from "lucide-react";
import { Separator } from "../components/ui/separator";
import furtherAiLogo from '../assets/fai-logo.svg';
import furtherAiLogoShort from '../assets/fai-logo-short.svg';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { User, withAuthInfo, useLogoutFunction, useRedirectFunctions, UserClass } from "@propelauth/react";
import toast from 'react-hot-toast';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import userDefaultImg from '../assets/avatars/user-img.png';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

export const SIDEBAR_WIDTH = "14rem";
export const SIDEBAR_WIDTH_ICON = "4rem";

interface SidebarButtonProps {
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
  onClick: () => void;
  isCollapsed: boolean;
}

const SidebarButton = ({ icon, label, isActive, onClick, isCollapsed }: SidebarButtonProps) => {
  const ButtonContent = (
    <Button
      variant="ghost"
      className={`w-full justify-start ${isActive
        ? 'bg-gray-100 text-blue-700 hover:text-blue-700'
        : 'text-gray-700 hover:bg-gray-100 hover:text-gray-700'
        }`}
      onClick={onClick}
    >
      {icon}
      {!isCollapsed && <span className="text-sm font-semibold">{label}</span>}
    </Button>
  );

  if (isCollapsed) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          {ButtonContent}
        </TooltipTrigger>
        <TooltipContent side="right" align="center" sideOffset={10} className="bg-gray-50">
          <p className="font-medium">{label}</p>
        </TooltipContent>
      </Tooltip>
    );
  }

  return ButtonContent;
};

interface HomeSidebarProps {
  isLoggedIn: boolean;
  user: User | null;
  userClass: UserClass | null;
  onSidebarToggle?: (isCollapsed: boolean) => void;
}

const HomeSidebarComponent = ({
  isLoggedIn,
  user,
  userClass,
  onSidebarToggle
}: HomeSidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState('');
  const { redirectToLoginPage } = useRedirectFunctions()
  const logoutFn = useLogoutFunction()
  const org = userClass?.getOrgs()[0];
  const isFurtherAI = org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];
  const isMSI = org?.orgMetadata["e18fc946-b145-4f5a-8c07-60abf5952af8_MSI"];
  const isAccelerant = org?.orgMetadata["fa584512-1794-479e-adc8-4c8834d6bd4d_ACCELERANT"];

  const [isCollapsed, setIsCollapsed] = useState(() => {
    const saved = localStorage.getItem('sidebarCollapsed');
    return saved ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    onSidebarToggle?.(isCollapsed);
  }, [isCollapsed, onSidebarToggle]);

  useEffect(() => {
    const path = location.pathname.replace(/^\//, '');
    setActiveButton(path || 'playground');
  }, [location]);

  const getIconProps = (route: string) => ({
    size: 18,
    strokeWidth: 1.5,
    className: `flex-shrink-0 ${isCollapsed ? "" : "mr-2"}`,
    color: activeButton === route ? "#1d4ed8" : "#18181B"
  });

  const handleButtonClick = (route: string) => {
    if (route === 'integrations' && !isFurtherAI && !isMSI) {
      toast.error("Contact your admin to enable this feature.")
    } else {
      setActiveButton(route);
      navigate(`/${route}`);
    }
  };

  const toggleSidebar = useCallback(() => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    localStorage.setItem('sidebarCollapsed', JSON.stringify(newState));
    onSidebarToggle?.(newState);
  }, [isCollapsed, onSidebarToggle]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key.toLowerCase() === 'b') {
        e.preventDefault();
        toggleSidebar();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [toggleSidebar]);

  return (
    <div
      className={`fixed top-0 left-0 flex flex-col h-screen bg-white border-r border-gray-200 overflow-y-auto overflow-x-hidden z-10`}
      style={{
        width: isCollapsed ? SIDEBAR_WIDTH_ICON : SIDEBAR_WIDTH,
        transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1)'
      }}
    >
      <div className="p-4 my-4 flex justify-between items-center">
        {!isCollapsed && (
          <img
            src={furtherAiLogo}
            alt="Further AI"
            className="h-6 max-w-full"
            width={160}
            height={34}
          />
        )}
        {!isCollapsed ? (
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleSidebar}
            className="hover:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 ml-2"
          >
            <PanelLeft className="h-4 w-4" />
          </Button>
        ) : (
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={toggleSidebar}
                  className="hover:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
                >
                  <img
                    src={furtherAiLogoShort}
                    alt="Expand"
                    className="h-5 w-5"
                  />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="right" align="center" sideOffset={10} className="bg-gray-50">
                <p className="font-medium">Expand Sidebar</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <nav className="flex-1 overflow-y-auto overflow-x-hidden">
        <TooltipProvider>
          {isFurtherAI && (
            <div className="mb-6">
              {!isCollapsed ? (
                <h2 className="px-4 text-xs font-medium text-gray-500 uppercase tracking-wider mb-2 truncate whitespace-nowrap overflow-hidden">
                  AI Assistants
                </h2>
              ) : (
                <div className="px-2 py-3">
                  <Separator className="border-t border-gray-200" />
                </div>
              )}
              <ul className="space-y-1 px-2">
                <SidebarButton
                  icon={<FileText {...getIconProps('submissions')} />}
                  label="Submissions"
                  isActive={activeButton === 'submissions'}
                  onClick={() => handleButtonClick('submissions')}
                  isCollapsed={isCollapsed}
                />

                <SidebarButton
                  icon={<SearchCheck {...getIconProps('audit')} />}
                  label="Audit"
                  isActive={activeButton === 'audit'}
                  onClick={() => handleButtonClick('audit')}
                  isCollapsed={isCollapsed}
                />
              </ul>
            </div>
          )}

          {isAccelerant && (
            <div className="mb-6">
              {!isCollapsed ? (
                <h2 className="px-4 text-xs font-medium text-gray-500 uppercase tracking-wider mb-2 truncate whitespace-nowrap overflow-hidden">
                  AI Assistants
                </h2>
              ) : (
                <div className="px-2 py-3">
                  <Separator className="border-t border-gray-200" />
                </div>
              )}
              <ul className="space-y-1 px-2">
                <SidebarButton
                  icon={<SearchCheck {...getIconProps('workflows/7558212c-ad88-402e-bcba-5535694e35b6')} />}
                  label="Guideline Check"
                  isActive={activeButton === 'workflows/7558212c-ad88-402e-bcba-5535694e35b6'}
                  onClick={() => handleButtonClick('workflows/7558212c-ad88-402e-bcba-5535694e35b6')}
                  isCollapsed={isCollapsed}
                />

                <SidebarButton
                  icon={<SearchCheck {...getIconProps('workflows/d17b051f-14a7-481f-b97c-a2b1d4e1b854')} />}
                  label="Authority Check"
                  isActive={activeButton === 'workflows/d17b051f-14a7-481f-b97c-a2b1d4e1b854'}
                  onClick={() => handleButtonClick('workflows/d17b051f-14a7-481f-b97c-a2b1d4e1b854')}
                  isCollapsed={isCollapsed}
                />

                {/* Use integrations so that it gives error toast when clicked */}
                <SidebarButton
                  icon={<SearchCheck {...getIconProps('integrations')} />}
                  label="Terrorism Check"
                  isActive={activeButton === 'integrations'}
                  onClick={() => handleButtonClick('integrations')}
                  isCollapsed={isCollapsed}
                />
              </ul>
            </div>
          )}

          <div className="mb-6">
            {!isCollapsed ? (
              <h2 className="px-4 text-xs font-medium text-gray-500 uppercase tracking-wider mb-2 truncate whitespace-nowrap overflow-hidden">
                AI Tools
              </h2>
            ) : (
              <div className="px-2 py-3">
                <Separator className="border-t border-gray-200" />
              </div>
            )}
            <ul className="space-y-1 px-2">
              <SidebarButton
                icon={<Sparkles {...getIconProps('q-and-a')} />}
                label="Q&A"
                isActive={activeButton === 'q-and-a'}
                onClick={() => handleButtonClick('q-and-a')}
                isCollapsed={isCollapsed}
              />
              <SidebarButton
                icon={<FileOutput {...getIconProps('extract')} />}
                label="Extract"
                isActive={activeButton === 'extract'}
                onClick={() => handleButtonClick('extract')}
                isCollapsed={isCollapsed}
              />

              <SidebarButton
                icon={<ChartColumnStacked {...getIconProps('compare')} />}
                label="Compare"
                isActive={activeButton === 'compare'}
                onClick={() => handleButtonClick('compare')}
                isCollapsed={isCollapsed}
              />

              <SidebarButton
                icon={<Table2 {...getIconProps('excel')} />}
                label="Excel"
                isActive={activeButton === 'excel'}
                onClick={() => handleButtonClick('excel')}
                isCollapsed={isCollapsed}
              />

              <SidebarButton
                icon={<PhoneCall {...getIconProps('call')} />}
                label="Call"
                isActive={activeButton === 'call'}
                onClick={() => handleButtonClick('call')}
                isCollapsed={isCollapsed}
              />

              <SidebarButton
                icon={<Blocks {...getIconProps('integrations')} />}
                label="Integrations"
                isActive={activeButton === 'integrations'}
                onClick={() => handleButtonClick('integrations')}
                isCollapsed={isCollapsed}
              />
            </ul>
          </div>
        </TooltipProvider>
      </nav>

      <Separator className="border-t border-gray-200" />
      {isLoggedIn && user && (
        <div className={`px-4 py-3 ${isCollapsed ? 'items-center' : ''}`}>
          <div className="flex items-center justify-between">
            {isCollapsed ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className="cursor-pointer">
                    <img
                      src={userDefaultImg}
                      alt={user.firstName}
                      className="rounded-full"
                      width={32}
                      height={32}
                    />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-52 p-1 bg-white"
                  align="center"
                  sideOffset={8}
                  forceMount
                >
                  <DropdownMenuItem
                    onClick={() => logoutFn(false)}
                    className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700 focus:bg-red-50 focus:text-red-700 rounded-md cursor-pointer"
                  >
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Log out</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <div className="flex items-center space-x-2">
                <img
                  src={userDefaultImg}
                  alt={user.firstName}
                  className="rounded-full"
                  width={32}
                  height={32}
                />
                <div className="flex flex-col items-start overflow-hidden">
                  <span className="text-sm font-medium text-gray-900 truncate max-w-[120px]">
                    {user.firstName} {user.lastName}
                  </span>
                  <span className="text-xs text-gray-600 truncate max-w-[120px]">
                    {user.email}
                  </span>
                </div>
              </div>
            )}
            {!isCollapsed && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    className="h-8 w-8 p-0 hover:bg-gray-100 rounded-full focus:ring-0 focus-visible:ring-0 focus:ring-offset-0"
                  >
                    <MoreVertical className="h-4 w-4 text-gray-400" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-52 p-1 bg-white"
                  align="end"
                  alignOffset={-5}
                  sideOffset={8}
                  forceMount
                >
                  <DropdownMenuItem
                    onClick={() => logoutFn(false)}
                    className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700 focus:bg-red-50 focus:text-red-700 rounded-md cursor-pointer"
                  >
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Log out</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
      )}
      {!isLoggedIn && (
        <div className="px-2 py-1 mb-4">
          <Button
            variant="ghost"
            className="w-full justify-start text-gray-700 hover:bg-gray-100 hover:text-gray-700"
            onClick={() => redirectToLoginPage()}
          >
            <LogIn {...getIconProps('login')} />
            <span className="text-sm font-medium text-gray-800">Login</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export const HomeSidebarV1 = withAuthInfo(HomeSidebarComponent) as unknown as (props: { onSidebarToggle?: (isCollapsed: boolean) => void }) => JSX.Element;
