import { SendHorizontal, Sparkles, TriangleAlert, X } from "lucide-react"
import { Button } from "../../components/ui/button"
import { useState, useEffect, useRef } from "react"
import { withRequiredAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react"
import { ScrollArea } from "../ui/scroll-area"
import { Input } from "../ui/input"
import { useNodeApi } from "../../hooks/useNodeApi";

type Message = {
  role: 'user' | 'assistant'
  content: string
  icon?: React.ReactNode
}

interface SubmissionAIChatProps extends WithLoggedInAuthInfoProps {
  workflowExecutionId?: string;
  onClose?: () => void;
}

export const SubmissionAIChat = withRequiredAuthInfo(({
  accessToken,
  userClass,
  workflowExecutionId,
  onClose
}: SubmissionAIChatProps) => {
  const [messages, setMessages] = useState<Message[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [input, setInput] = useState('')
  const userName = `${userClass?.firstName} ${userClass?.lastName}`.trim() || "there"
  const scrollAreaRef = useRef<HTMLDivElement>(null)
  const { fetchWorkkflowExecutionAskAI } = useNodeApi(accessToken);

  useEffect(() => {
    const scrollableElement = scrollAreaRef.current?.querySelector('[data-radix-scroll-area-viewport]')
    if (scrollableElement) {
      scrollableElement.scrollTop = scrollableElement.scrollHeight
    }
  }, [messages])

  const getAIResponse = async (question: string) => {
    setIsLoading(true)
    setMessages(prev => [...prev, {
      role: 'user',
      content: question,
    }])

    if (workflowExecutionId) {
      const response = await fetchWorkkflowExecutionAskAI(workflowExecutionId, question);
      const icon = <Sparkles className="h-5 w-5" />
      setMessages(prev => [...prev, { role: 'assistant', content: response.answer, icon }])
    } else {
      const icon = <TriangleAlert className="h-5 w-5" />
      setMessages(prev => [...prev, { role: 'assistant', content: "I'm sorry, Ask AI is not enabled for this page.", icon }])
    }

    setIsLoading(false)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (input.trim()) {
      getAIResponse(input)
      setInput('')
    }
  }

  return (
    <div className="flex flex-col h-full">
      <div className="p-4 border-b flex justify-between items-center">
        <h2 className="text-lg font-semibold">Ask AI</h2>
        {onClose && (
          <Button
            variant="ghost"
            size="sm"
            onClick={onClose}
            className="hover:bg-gray-100"
          >
            <X className="h-4 w-4" />
          </Button>
        )}
      </div>

      <div className="flex-1 overflow-hidden">
        <ScrollArea
          ref={scrollAreaRef}
          className="h-[calc(100vh-120px)]"
          type="auto"
        >
          <div className="p-4 space-y-4">
            {messages.length === 0 && (
              <div className="space-y-2">
                <h2 className="text-[#4B6BFB] text-md font-semibold">Hi, {userName}!</h2>
                <p className="text-md">How can I help you today?</p>
              </div>
            )}

            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex items-start space-x-3 ${message.role === 'user'
                  ? 'justify-end'
                  : 'justify-start'
                  }`}
              >
                {message.icon && <div className="mt-1">{message.icon}</div>}
                <div className="text-sm py-2 px-3 rounded-lg bg-gray-100">
                  {message.content}
                </div>
              </div>
            ))}

            {isLoading && (
              <div className="flex items-center justify-center space-x-2">
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
              </div>
            )}

            {messages.length === 0 && (
              <div className="space-y-3">
                <Button
                  onClick={() => getAIResponse("What are the submissions about?")}
                  disabled={isLoading}
                  variant="outline"
                  className="w-full flex items-center space-x-3 p-3 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  <Sparkles className="h-5 w-5" />
                  <span>What are the submissions about?</span>
                </Button>

                <Button
                  onClick={() => getAIResponse("Summarize the audit results")}
                  disabled={isLoading}
                  variant="outline"
                  className="w-full flex items-center space-x-3 p-3 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  <Sparkles className="h-5 w-5" />
                  <span>Summarize the audit results</span>
                </Button>
              </div>
            )}
          </div>
        </ScrollArea>
      </div>

      <div className="p-4">
        <form onSubmit={handleSubmit}>
          <div className="relative">
            <Input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Ask"
              className="w-full pr-10"
            />
            <Button
              type="submit"
              disabled={isLoading || !input.trim()}
              variant="ghost"
              className="absolute right-0 top-1/2 -translate-y-1/2 p-2 text-gray-400 hover:text-gray-600 disabled:opacity-50"
            >
              <SendHorizontal className="h-4 w-4" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
})
