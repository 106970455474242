import React, { useEffect, useRef, TextareaHTMLAttributes } from 'react';
import { cn } from "../lib/utils";

interface AutoResizeTextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const AutoResizeTextarea: React.FC<AutoResizeTextareaProps> = ({
  value,
  onChange,
  className,
  readOnly,
  ...props
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      className={cn(
        "w-full min-h-[200px] text-sm placeholder-transparent resize-none overflow-hidden",
        "focus:outline-none focus:ring-0 focus:border-transparent",
        readOnly ? "bg-transparent" : "bg-white",
        className
      )}
      style={{
        fontWeight: 'normal',
        color: '#333',
        border: 'none',
      }}
      {...props}
    />
  );
};
