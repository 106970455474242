import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { X, Search, FileStack, Sparkles, Filter } from 'lucide-react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { CitationWithState, CompareLogResponse, Citation } from '../../services/api';
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Badge } from "../../components/ui/badge";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs";

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

interface ComparisonCardProps {
  title: string;
  state: 'match' | 'mismatch';
  value: string;
  onCardClick: (rowIndex: number, colIndex: number) => void;
}

interface Row {
  title: string;
  data: string[];
  num_mismatches: number;
  citations?: Citation[][] | Record<string, Citation[]>[] | null;
}

interface SideBySideComparisonProps {
  isOpen: boolean;
  onClose: () => void;
  leftPdfUrl: string | null;
  rightPdfUrl: string | null;
  citations: CitationWithState[];
  rows: Row[];
  onCardClick: (rowIndex: number, colIndex: number) => void;
  extractCitations: (compareLog: CompareLogResponse, rowIndex: number, colIndex: number) => CitationWithState[];
  compareLog: CompareLogResponse | null;
}

const ComparisonCard: React.FC<ComparisonCardProps> = ({
  title,
  state,
  value,
  onCardClick
}) => {
  return (
    <div
      className="bg-white rounded-lg border p-3 space-y-1.5 cursor-pointer hover:bg-gray-50"
      onClick={() => onCardClick(0, 0)}
    >
      <div className="flex items-center space-x-2">
        <Badge
          variant={state === 'match' ? 'default' : 'destructive'}
          className={`
            ${state === 'match'
              ? 'bg-green-100 text-green-600 hover:bg-green-100'
              : 'bg-red-100 text-red-600 hover:bg-red-100'
            }
            text-xs py-0.5
          `}
        >
          {state === 'match' ? 'Match' : 'Mismatch'}
        </Badge>
      </div>
      <h3 className="text-sm font-medium">{title}</h3>
      <p className="text-xs text-gray-600">{value}</p>
    </div>
  );
};

const LoadingIndicator: React.FC<{ percentages: number }> = () => (
  <div className="h-full w-full p-6 bg-gray-50">
    <div className="h-full w-full flex flex-col gap-4">
      <div className="h-8 w-1/3 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 bg-[length:200%_100%] animate-[shimmer_1.5s_infinite_linear] rounded-md" />
      <div className="flex-1 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 bg-[length:200%_100%] animate-[shimmer_1.5s_infinite_linear] rounded-lg shadow-sm" />
    </div>
  </div>
);

const ShimmerCard: React.FC = () => (
  <div className="bg-white rounded-lg border p-3 space-y-1.5">
    <div className="flex items-center space-x-2">
      <div className="w-16 h-5 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 bg-[length:200%_100%] animate-[shimmer_1.5s_infinite_linear] rounded-full" />
    </div>
    <div className="w-3/4 h-4 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 bg-[length:200%_100%] animate-[shimmer_1.5s_infinite_linear] rounded" />
    <div className="w-full h-12 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 bg-[length:200%_100%] animate-[shimmer_1.5s_infinite_linear] rounded" />
  </div>
);

export const SideBySideComparison: React.FC<SideBySideComparisonProps> = (props) => {
  if (!props.isOpen) {
    return null;
  }

  return <SideBySideComparisonContent {...props} />;
};

const SideBySideComparisonContent: React.FC<SideBySideComparisonProps> = ({
  isOpen,
  onClose,
  leftPdfUrl,
  rightPdfUrl,
  citations,
  rows,
  onCardClick,
  extractCitations,
  compareLog,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('comparison');
  const [activeCitations, setActiveCitations] = useState<CitationWithState[]>([]);
  const [initialPage, setInitialPage] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [leftPdfLoaded, setLeftPdfLoaded] = useState(false);
  const [rightPdfLoaded, setRightPdfLoaded] = useState(false);

  const leftPageNavigationPluginInstance = pageNavigationPlugin();
  const rightPageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage: jumpToLeftPage } = leftPageNavigationPluginInstance;
  const { jumpToPage: jumpToRightPage } = rightPageNavigationPluginInstance;

  const sortedHighlights = useMemo(() => {
    const highlightAreas = activeCitations.map((citation) => ({
      pageIndex: citation.citation.bbox.page - 1,
      left: citation.citation.bbox.left * 100,
      top: citation.citation.bbox.top * 100,
      height: citation.citation.bbox.height * 100,
      width: citation.citation.bbox.width * 100,
      color: citation.state === 'match' ? 'rgba(0, 255, 0, 0.3)' : 'rgba(255, 0, 0, 0.3)',
    }));

    return [...highlightAreas].sort((a, b) => a.pageIndex - b.pageIndex || a.top - b.top);
  }, [activeCitations]);

  const renderHighlights = useCallback((_side: 'left' | 'right') => {
    function HighlightLayer(props: RenderHighlightsProps) {
      return (
        <div>
          {sortedHighlights
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                className="highlight-area"
                style={{
                  position: 'absolute',
                  left: `${area.left}%`,
                  top: `${area.top}%`,
                  width: `${area.width}%`,
                  height: `${area.height}%`,
                  background: area.color,
                  mixBlendMode: 'multiply',
                  pointerEvents: 'none',
                }}
              />
            ))}
        </div>
      );
    }

    HighlightLayer.displayName = 'HighlightLayer';

    return HighlightLayer;
  }, [sortedHighlights]);

  useEffect(() => {
    if (!isOpen) return;

    if (rows.length > 0 && compareLog && !citations.length) {
      const initialCitations = extractCitations(compareLog, 0, 0);
      setActiveCitations(initialCitations);

      if (initialCitations.length > 0) {
        const firstHighlightPage = initialCitations[0].citation.bbox.page - 1;
        setInitialPage(firstHighlightPage);
        jumpToLeftPage(firstHighlightPage);
        jumpToRightPage(firstHighlightPage);
      }
    } else if (citations.length > 0) {
      setActiveCitations(citations);
      const firstHighlightPage = citations[0].citation.bbox.page - 1;
      setInitialPage(firstHighlightPage);
      jumpToLeftPage(firstHighlightPage);
      jumpToRightPage(firstHighlightPage);
    }
  }, [compareLog, rows, citations, extractCitations, jumpToLeftPage, jumpToRightPage, isOpen]);

  const handleCardClick = useCallback((rowIndex: number, colIndex: number) => {
    if (!compareLog) return;

    const newCitations = extractCitations(compareLog, rowIndex, colIndex);
    setActiveCitations(newCitations);

    if (newCitations.length > 0) {
      const targetPage = newCitations[0].citation.bbox.page - 1;

      setInitialPage(targetPage);

      jumpToLeftPage(targetPage);
      jumpToRightPage(targetPage);
    }

    onCardClick(rowIndex, colIndex);
  }, [compareLog, extractCitations, jumpToLeftPage, jumpToRightPage, onCardClick]);

  const renderCard = (rowIndex: number, colIndex: number, title: string, value: string, state: 'match' | 'mismatch') => (
    <ComparisonCard
      key={`${rowIndex}-${colIndex}`}
      title={title}
      state={state}
      value={value}
      onCardClick={() => handleCardClick(rowIndex, colIndex)}
    />
  );

  useEffect(() => {
    setIsLoading(!leftPdfLoaded || !rightPdfLoaded);
  }, [leftPdfLoaded, rightPdfLoaded]);

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-30">
      <div
        ref={containerRef}
        className="fixed inset-0 z-50 bg-white"
      >
        <div className="h-16 border-b flex items-center justify-between px-4">
          <h2 className="text-lg font-semibold">Compare Documents</h2>
          <Button
            onClick={onClose}
            variant="ghost"
            className="hover:bg-transparent"
          >
            <X className="h-6 w-6" />
          </Button>
        </div>

        <div className="h-[calc(100vh-3rem)] flex">
          <div className="flex-1 flex p-4 gap-2">
            <div className="flex-1 border rounded-lg overflow-hidden shadow-sm relative">
              {leftPdfUrl && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={leftPdfUrl}
                      plugins={[
                        highlightPlugin({
                          renderHighlights: renderHighlights('left'),
                          trigger: Trigger.None,
                        }),
                        leftPageNavigationPluginInstance,
                      ]}
                      defaultScale={SpecialZoomLevel.PageWidth}
                      initialPage={initialPage}
                      renderLoader={() => <LoadingIndicator percentages={0} />}
                      onDocumentLoad={() => {
                        if (sortedHighlights.length > 0) {
                          jumpToLeftPage(initialPage);
                        }
                        setLeftPdfLoaded(true);
                      }}
                    />
                  </Worker>
                )}
            </div>

            <div className="flex-1 border rounded-lg overflow-hidden shadow-sm relative">
              {rightPdfUrl && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={rightPdfUrl}
                      plugins={[
                        highlightPlugin({
                          renderHighlights: renderHighlights('right'),
                          trigger: Trigger.None,
                        }),
                        rightPageNavigationPluginInstance,
                      ]}
                      defaultScale={SpecialZoomLevel.PageWidth}
                      initialPage={initialPage}
                      renderLoader={() => <LoadingIndicator percentages={0} />}
                      onDocumentLoad={() => {
                        if (sortedHighlights.length > 0) {
                          jumpToRightPage(initialPage);
                        }
                        setRightPdfLoaded(true);
                      }}
                    />
                  </Worker>
                )}
            </div>
          </div>

          <div className="w-96 border-l bg-white flex flex-col">
            <div className="p-4 border-b">
              <Tabs defaultValue="comparison" className="w-full" onValueChange={setActiveTab}>
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger value="comparison" className="flex items-center">
                    <FileStack className="h-4 w-4 mr-2" />
                    Comparison
                  </TabsTrigger>
                  <TabsTrigger value="askAi" className="flex items-center">
                    <Sparkles className="h-4 w-4 mr-2" />
                    Ask AI
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>

            {activeTab === 'comparison' && (
              <>
                <div className="p-3">
                  <div className="flex space-x-2">
                    <div className="relative flex-1">
                      <Search className="absolute left-2 top-2 h-4 w-4 text-gray-500" />
                      <Input
                        placeholder="Search..."
                        className="pl-8 h-8 text-sm"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                    <Button variant="outline" size="icon" className="h-8 w-8">
                      <span className="sr-only">Filter</span>
                      <Filter className="h-4 w-4" />
                    </Button>
                  </div>
                </div>

                <ScrollArea className="flex-1">
                  <div className="p-3 space-y-3">
                    {isLoading ? (
                      Array(5).fill(0).map((_, index) => (
                        <ShimmerCard key={index} />
                      ))
                    ) : (
                      rows.map((row, rowIndex) => {
                        if (row.num_mismatches === 0) {
                          return renderCard(rowIndex, 0, row.title, row.data[0], 'match');
                        }

                        return (
                          <div key={rowIndex} className="space-y-2">
                            {row.data.map((value, colIndex) =>
                              renderCard(rowIndex, colIndex, row.title, value, 'mismatch')
                            )}
                          </div>
                        );
                      })
                    )}
                    <div className="h-6" />
                  </div>
                </ScrollArea>
              </>
            )}

            {activeTab === 'askAi' && (
              <div className="p-4">
                <p>AI Assistant interface coming soon...</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SideBySideComparison.displayName = 'SideBySideComparison';
