import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { CitationWithState } from '../../services/api';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from '../ui/resizable';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

interface PDFPreviewSliderProps {
  isOpen: boolean;
  onClose: () => void;
  pdfUrl: string | null;
  citations: CitationWithState[] | null;
  isLoading: boolean;
}

interface HighlightArea {
  pageIndex: number;
  left: number;
  top: number;
  width: number;
  height: number;
  color: string;
}

export const PDFPreviewSlider: React.FC<PDFPreviewSliderProps> = React.memo(({
  isOpen,
  onClose,
  pdfUrl,
  citations,
  isLoading,
}) => {
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const highlights: HighlightArea[] = useMemo(() => {
    if (!Array.isArray(citations)) {
      return [];
    }
    return citations.map((citationWithState) => ({
      pageIndex: citationWithState.citation.bbox.page - 1,
      left: citationWithState.citation.bbox.left * 100,
      top: citationWithState.citation.bbox.top * 100,
      height: citationWithState.citation.bbox.height * 100,
      width: citationWithState.citation.bbox.width * 100,
      color: citationWithState.state === 'match' ? 'rgba(0, 255, 0, 0.3)' : 'rgba(255, 0, 0, 0.3)', // tweak 4th value to change opacity
    }));
  }, [citations]);

  const sortedHighlights = useMemo(() =>
    [...highlights].sort((a, b) => a.pageIndex - b.pageIndex || a.top - b.top),
    [highlights]
  );

  const navigateHighlight = useCallback((direction: 'next' | 'prev') => {
    if (sortedHighlights.length === 0) return;
    const newIndex = direction === 'next'
      ? (currentHighlightIndex + 1) % sortedHighlights.length
      : (currentHighlightIndex - 1 + sortedHighlights.length) % sortedHighlights.length;

    setCurrentHighlightIndex(newIndex);
    jumpToPage(sortedHighlights[newIndex].pageIndex);
  }, [currentHighlightIndex, sortedHighlights, jumpToPage]);

  const renderHighlights = useCallback((props: { pageIndex: number }) => {
    return (
      <div>
        {sortedHighlights
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area"
              style={{
                position: 'absolute',
                left: `${area.left}%`,
                top: `${area.top}%`,
                width: `${area.width}%`,
                height: `${area.height}%`,
                background: area.color,
                mixBlendMode: 'multiply',
                pointerEvents: 'none',
              }}
            />
          ))}
      </div>
    );
  }, [sortedHighlights]);

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Ensure currentHighlightIndex is always valid
  const safeCurrentIndex = useMemo(() => {
    return Math.min(currentHighlightIndex, sortedHighlights.length - 1);
  }, [currentHighlightIndex, sortedHighlights]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-30">
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel className="bg-transparent" collapsible={false} minSize={25}>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel className="bg-white shadow-xl" minSize={40.5}>
          <div ref={containerRef} className="relative h-full w-full">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 z-10 text-black hover:text-gray-700 focus:outline-none transition-colors bg-white/20 backdrop-blur-sm rounded-full p-1.5"
              aria-label="Close PDF preview"
            >
              <X className="h-5 w-5" />
            </button>
            <div className="w-full h-full">
              {pdfUrl && !isLoading && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={pdfUrl}
                    plugins={[highlightPluginInstance, pageNavigationPluginInstance]}
                    defaultScale={SpecialZoomLevel.PageFit}
                    initialPage={sortedHighlights.length > 0 ? sortedHighlights[0].pageIndex : 0}
                    theme="dark"
                  />
                </Worker>
              )}
            </div>
            {sortedHighlights.length > 0 && (
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center space-x-4 bg-white/20 backdrop-blur-sm rounded-full px-4 py-2">
                <button
                  onClick={() => navigateHighlight('prev')}
                  className="text-black hover:text-gray-700 focus:outline-none transition-colors"
                  aria-label="Previous highlight"
                >
                  <ChevronLeft className="h-5 w-5" />
                  <span className="sr-only">Previous</span>
                </button>
                <div className="text-sm text-black">
                  Highlight {sortedHighlights.length > 0 ? safeCurrentIndex + 1 : 0} of {sortedHighlights.length}
                </div>
                <button
                  onClick={() => navigateHighlight('next')}
                  className="text-black hover:text-gray-700 focus:outline-none transition-colors"
                  aria-label="Next highlight"
                >
                  <ChevronRight className="h-5 w-5" />
                  <span className="sr-only">Next</span>
                </button>
              </div>
            )}
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
});

PDFPreviewSlider.displayName = 'PDFPreviewSlider';
