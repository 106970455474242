export function NoMatch() {
  return (
    <div className="text-black bg-white m-0 h-screen flex items-center justify-center">
      <div>
        <h1 className="inline-block m-0 mr-5 pr-6 text-2xl font-medium align-top leading-[49px] border-r border-r-[rgba(0,0,0,0.3)] next-error-h1">
          404
        </h1>
        <div className="inline-block">
          <h2 className="text-sm font-normal leading-[49px] m-0">
            This page could not be found.
          </h2>
        </div>
      </div>
    </div>
  )
}
