import { ReactNode } from 'react';
import { HomeSidebarV1, SIDEBAR_WIDTH, SIDEBAR_WIDTH_ICON } from './HomeSidebarV1';
import { useSidebarState } from './useSidebarState';

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { isCollapsed, handleSidebarToggle } = useSidebarState();

  return (
    <div className="flex h-screen overflow-hidden">
      <HomeSidebarV1 onSidebarToggle={handleSidebarToggle} />
      <main
        className="flex-1 overflow-y-auto transition-all duration-300 ease-in-out bg-gray-50"
        style={{
          marginLeft: isCollapsed ? SIDEBAR_WIDTH_ICON : SIDEBAR_WIDTH,
          width: `calc(100% - ${isCollapsed ? SIDEBAR_WIDTH_ICON : SIDEBAR_WIDTH})`
        }}
      >
        {children}
      </main>
    </div>
  );
};
// MainLayout is the layout for the app, it includes the sidebar and the main content area
