import { useState, useRef, useMemo } from "react"
import { Button } from "../ui/button"
import { Search, ChevronUp, ChevronDown, ChevronLeft, ChevronRight, MoreHorizontal, Plus, Settings2 } from "lucide-react"
import { Input } from "../ui/input"
import { useNodeApi } from '../../hooks/useNodeApi';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table"
import { Checkbox } from "../ui/checkbox"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import av1 from '../../assets/avatars/av1.svg';
import { useNavigate } from 'react-router-dom'
import { UserDocument, AuditLogResponse, deleteLogByCategory } from '../../services/api';
import toast from 'react-hot-toast';
import { withRequiredAuthInfo } from "@propelauth/react";
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import { UploadDialog } from "../UploadDialog"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu"
import { Trash2 } from "lucide-react"
import { MainLayout } from "../MainLayout"

type AuditData = {
  id: string;
  personWhoStartedIt: string;
  personImage: string;
  templateName: string;
  numberOfDocuments: number;
  numberOfFieldsMatch: number;
  numberOfFieldsMismatch: number;
  numberOfFieldsNeedsInfo: number;
  date: string;
  time: string;
};

type Schema = {
  name: string;
  value: AuditType;
  isCustom?: boolean;
};

enum AuditType {
  VERIFY_GUIDELINE = "verify_guideline_2",
  UNDERWRITER_AUTHORIZATION = "underwriter_authorization",
  CUSTOM = "custom"
}

const auditTypeToDisplayName: Record<string, string> = {
  "verify_guideline": "Check Guideline",
  "verify_guideline_2": "Check Guideline",
  "underwriter_authorization": "Check Authorization",
  "custom": "Custom"
};

const getDisplayName = (templateName: string): string => {
  return auditTypeToDisplayName[templateName] || templateName;
};

const pdfFileTypes = {
  mimeTypes: ['application/pdf'],
  errorMessage: 'Please upload PDF files only.'
};

const columnFilterOptions = [
  { id: "templateName", label: "Template" },
  { id: "numberOfDocuments", label: "Document Count" },
  { id: "numberOfFieldsMatch", label: "Matched" },
  { id: "numberOfFieldsMismatch", label: "Mismatched" },
  { id: "numberOfFieldsNeedsInfo", label: "Needs Info" },
  { id: "date", label: "Date" },
  { id: "time", label: "Time" },
];

export const UwAudit = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  const navigate = useNavigate()
  const { uploadDocument, runAudit, getAuditLogsHistory, deleteDocument, executeWorkflowV1Request } = useNodeApi(accessToken);
  const [auditLogs, setAuditLogs] = useState<AuditLogResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState<{ key: keyof AuditData; direction: 'asc' | 'desc' | null }>({ key: 'id', direction: null });
  const [visibleColumns, setVisibleColumns] = useState(
    columnFilterOptions.map(col => ({ ...col, visible: true }))
  );

  const lastPollTimeRef = useRef(0);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedSchema, setSelectedSchema] = useState<Schema | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsUploadDialogOpen(false);
    setSelectedSchema(null);
  };

  const handleRowSelection = (id: string, isChecked: boolean) => {
    setSelectedRows(prev =>
      isChecked
        ? [...prev, id]
        : prev.filter(rowId => rowId !== id)
    );
  };

  const handleColumnToggle = (columnId: string, isChecked: boolean) => {
    setVisibleColumns(prev =>
      prev.map(col => (col.id === columnId ? { ...col, visible: isChecked } : col))
    );
  };

  const handleSelectAllRows = (checked: boolean) => {
    if (checked) {
      const newSelectedRows = selectedRows.slice();
      paginatedData.forEach(row => {
        if (!newSelectedRows.includes(row.id)) {
          newSelectedRows.push(row.id);
        }
      });
      setSelectedRows(newSelectedRows);
    } else {
      const currentPageIds = paginatedData.map(row => row.id);
      setSelectedRows(selectedRows.filter(id => !currentPageIds.includes(id)));
    }
  };

  const requestSort = (key: keyof AuditData) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        if (currentConfig.direction === 'asc') {
          return { key, direction: 'desc' };
        }
        if (currentConfig.direction === 'desc') {
          return { key, direction: null };
        }
      }
      return { key, direction: 'asc' };
    });
  };

  usePolling(
    () => {
      setIsHistoryLoading(true);
      return getAuditLogsHistory();
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: true,
      lastPollTimeRef,
      onSuccess: (logs) => {
        setAuditLogs(logs);
        setIsHistoryLoading(false);
      },
      onError: (error) => {
        console.error("Failed to fetch audit logs:", error);
        setIsHistoryLoading(false);
      }
    }
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const formatDate = (created_at: string): string => {
    const startDate = new Date(created_at + "Z");
    return startDate.toLocaleDateString();
  };

  const formatTime = (created_at: string): string => {
    const startDate = new Date(created_at + "Z");
    return startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const sortedAndFilteredData = useMemo(() => {
    return auditLogs.map((log) => ({
      id: log._id,
      personWhoStartedIt: "User",
      personImage: av1,
      templateName: log.request.audit_type,
      numberOfDocuments: 1,
      numberOfFieldsMatch: log.result?.rows.filter(row => row.status.toLowerCase() === "match").length || 0,
      numberOfFieldsMismatch: log.result?.rows.filter(row => row.status.toLowerCase() === "mismatch").length || 0,
      numberOfFieldsNeedsInfo: log.result?.rows.filter(row => row.status.toLowerCase() === "needs info").length || 0,
      date: formatDate(log.created_at),
      time: formatTime(log.created_at)
    }))
      .filter((item) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          item.templateName.toLowerCase().includes(searchLower) ||
          item.date.toLowerCase().includes(searchLower) ||
          item.time.toLowerCase().includes(searchLower) ||
          item.numberOfDocuments.toString().includes(searchQuery) ||
          item.numberOfFieldsMatch.toString().includes(searchQuery) ||
          item.numberOfFieldsMismatch.toString().includes(searchQuery) ||
          item.numberOfFieldsNeedsInfo.toString().includes(searchQuery)
        );
      })
      .sort((a, b) => {
        if (sortConfig.direction === null) return 0;
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [auditLogs, searchQuery, sortConfig]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedAndFilteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedAndFilteredData, currentPage, rowsPerPage]);

  const columnWidths = {
    checkbox: "w-[60px]",
    templateName: "w-[160px]",
    numberOfDocuments: "w-[140px]",
    numberOfFieldsMatch: "w-[140px]",
    numberOfFieldsMismatch: "w-[140px]",
    numberOfFieldsNeedsInfo: "w-[140px]",
    date: "w-[100px]",
    time: "w-[100px]",
    actions: "w-[60px]"
  } as const;

  const SortableTableHead = ({ children, sortKey, className }: {
    children: React.ReactNode,
    sortKey: keyof AuditData,
    className?: string
  }) => (
    <TableHead className={` ${className}`}>
      <Button
        variant="ghost"
        onClick={() => requestSort(sortKey)}
        className="hover:bg-transparent w-full justify-start -ml-4 px-4 h-full whitespace-nowrap"
        disabled={isHistoryLoading}
      >
        <span>{children}</span>
        {sortConfig.key === sortKey && (
          <span className="ml-2">
            {sortConfig.direction === 'asc' ?
              <ChevronUp className="h-4 w-4" /> :
              <ChevronDown className="h-4 w-4" />
            }
          </span>
        )}
      </Button>
    </TableHead>
  );

  const handleRowClick = (audit: AuditData) => {
    navigate(`/audit/logs/${audit.id}`)
  }

  const renderShimmerRows = () => {
    return Array(rowsPerPage).fill(0).map((_, index) => (
      <TableRow key={`shimmer-${index}`}>
        <TableCell className="border-y border-l border-gray-200">
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-r border-gray-200">
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
      </TableRow>
    ));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleUploadComplete = async (documents: UserDocument[]) => {
    await new Promise(resolve => setTimeout(resolve, 0));

    if (!selectedSchema) {
      toast.error('Please select an audit type before running the audit.');
      return;
    }

    try {
      const documentIds = documents.map(doc => doc._id);
      const result = await runAudit(documentIds, selectedSchema.value);
      navigate(`/audit/logs/${result.audit_log_id}`);
    } catch (error) {
      console.error('Failed to initiate audit:', error);
      toast.error('Failed to initiate audit. Please try again.');
    }
  };

  const handleDelete = async (id: string, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await deleteLogByCategory('audit', id, accessToken);
      setAuditLogs(prevLogs => prevLogs.filter(log => log._id !== id));
      toast.success('Audit log deleted successfully');
    } catch (error) {
      console.error('Failed to delete audit log:', error);
      toast.error('Failed to delete audit log');
    }
  };

  const handleWorkflowSend = async (message: string, documentIds: string[]) => {
    try {
      // const workflow = await createWorkflowV1Request("New Workflow", {});
      const workflow_id = "7558212c-ad88-402e-bcba-5535694e35b6"
      const execute = await executeWorkflowV1Request(
        workflow_id,
        message,
        documentIds
      );

      navigate(`/workflow-execution/${execute.workflow_execution_id}`);
    } catch (error) {
      console.error('Failed to start workflow:', error);
      toast.error('Failed to start workflow');
    }
  };

  return (
    <MainLayout>
      <div className="max-w-6xl mx-auto px-2 py-8">
        <div>
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-xl font-semibold">Audit</h1>
            <div className="flex items-center gap-3">
              <Button variant="outline">
                Preview workflow
              </Button>
              <Button onClick={() => setIsUploadDialogOpen(true)}>
                <Plus className="h-4 w-4 mr-2" />
                Create new audit
              </Button>
            </div>
          </div>

          <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
            <div className="p-4">
              <div className="flex items-center justify-between mb-4">
                <div className="relative">
                  <Input
                    type="text"
                    placeholder="Search"
                    className="pl-8 pr-4 py-1 w-48 bg-white border-gray-300 text-gray-900 focus:ring-0 h-9"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      className="h-9 flex items-center text-gray-900 border-gray-300"
                    >
                      <Settings2 className="h-4 w-4 mr-2" />
                      View
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-[200px] bg-white shadow-lg rounded-md">
                    <DropdownMenuLabel>Filter Columns</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {columnFilterOptions.map((column) => (
                      <DropdownMenuCheckboxItem
                        key={column.id}
                        className="capitalize"
                        checked={visibleColumns.find((col) => col.id === column.id)?.visible}
                        onCheckedChange={(isChecked) => handleColumnToggle(column.id, isChecked)}
                      >
                        {column.label}
                      </DropdownMenuCheckboxItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>

              <div className="overflow-x-auto rounded-md border border-gray-200">
                <Table className="table-fixed w-full">
                  <TableHeader>
                    <TableRow>
                      <TableHead className={`${columnWidths.checkbox}`}>
                        {isHistoryLoading ? (
                          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
                        ) : (
                          <Checkbox
                            checked={paginatedData.length > 0 && paginatedData.every(row => selectedRows.includes(row.id))}
                            onCheckedChange={handleSelectAllRows}
                          />
                        )}
                      </TableHead>
                      {visibleColumns
                        .filter((col) => col.visible)
                        .map((column) => (
                          <SortableTableHead
                            key={column.id}
                            sortKey={column.id as keyof AuditData}
                            className={columnWidths[column.id as keyof typeof columnWidths]}
                          >
                            {column.label}
                          </SortableTableHead>
                        ))}
                      <TableHead className={`${columnWidths.actions}`} />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {isHistoryLoading ? (
                      renderShimmerRows()
                    ) : (
                      paginatedData.map((row) => (
                        <TableRow
                          key={row.id}
                          onClick={() => handleRowClick(row)}
                          className="cursor-pointer hover:bg-gray-50"
                        >
                          <TableCell className={`pl-4 ${columnWidths.checkbox}`}>
                            <Checkbox
                              checked={selectedRows.includes(row.id)}
                              onCheckedChange={(checked) =>
                                handleRowSelection(row.id, checked as boolean)
                              }
                              onClick={(e) => e.stopPropagation()}
                            />
                          </TableCell>
                          {visibleColumns
                            .filter((col) => col.visible)
                            .map((column) => (
                              <TableCell
                                key={column.id}
                                className={`px-4 ${columnWidths[column.id as keyof typeof columnWidths]}`}
                              >
                                {column.id === "templateName" ? (
                                  <span className="truncate">
                                    {getDisplayName(row.templateName)}
                                  </span>
                                ) : (
                                  row[column.id as keyof AuditData]
                                )}
                              </TableCell>
                            ))}
                          <TableCell className={`px-4 ${columnWidths.actions}`}>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
                                <Button
                                  variant="ghost"
                                  size="sm"
                                  className="h-8 w-8 p-0 hover:bg-gray-100"
                                >
                                  <MoreHorizontal className="h-4 w-4 text-gray-400" />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent
                                className="w-40 p-1 bg-white"
                                align="end"
                                alignOffset={-5}
                                sideOffset={8}
                                forceMount
                              >
                                <DropdownMenuItem
                                  onClick={(e) => handleDelete(row.id, e)}
                                  className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700"
                                >
                                  <Trash2 className="mr-2 h-4 w-4" />
                                  <span>Delete</span>
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </div>

              <div className="flex justify-between items-center mt-4">
                <p className="text-sm text-gray-500">
                  {isHistoryLoading
                    ? <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                    : `${selectedRows.length} of ${sortedAndFilteredData.length} row(s) selected.`
                  }
                </p>
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-500">Rows per page</span>
                  <Select value={rowsPerPage.toString()} onValueChange={(value) => setRowsPerPage(Number(value))}>
                    <SelectTrigger className="w-[70px] focus:ring-0 bg-white">
                      <SelectValue placeholder={rowsPerPage.toString()} />
                    </SelectTrigger>
                    <SelectContent className="bg-white">
                      <SelectItem value="5">5</SelectItem>
                      <SelectItem value="10">10</SelectItem>
                      <SelectItem value="20">20</SelectItem>
                    </SelectContent>
                  </Select>
                  <span className="text-sm text-gray-500">
                    Page {currentPage} of {Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                  </span>
                  <div className="flex space-x-1">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                      disabled={currentPage === 1}
                    >
                      <ChevronLeft className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(sortedAndFilteredData.length / rowsPerPage)))}
                      disabled={currentPage === Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                    >
                      <ChevronRight className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UploadDialog
        isOpen={isUploadDialogOpen}
        onClose={handleDialogClose}
        onUploadComplete={(documents) => handleWorkflowSend("", documents.map(doc => doc._id))}
        uploadDocument={uploadDocument}
        deleteDocument={deleteDocument}
        selectedTemplate=""
        acceptedFileTypes={pdfFileTypes}
        category="audit"
      />
    </MainLayout>
  )
})
