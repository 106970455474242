import { useState, useRef, useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { POLLING_CONFIG } from '../config/pollingConfig';

interface PollingConfig<T> {
  interval?: number;
  maxInterval?: number;
  backoffMultiplier?: number;
  enabled?: boolean;
  showToasts?: boolean;
  retryOnVisibilityChange?: boolean;
  maxRetries?: number;
  onSuccess?: (data: T) => boolean | void;
  onError?: (error: unknown) => void;
  onOfflineChange?: (isOffline: boolean) => void;
  lastPollTimeRef?: React.MutableRefObject<number>;
  minPollingInterval?: number;
  continuePollingOnSuccess?: boolean;
  isComplete?: (data: T) => boolean;
}

interface ApiErrorResponse {
  status: number;
  data?: unknown;
  message?: string;
}

interface PollingError extends Error {
  response?: ApiErrorResponse;
  isAxiosError?: boolean;
  status?: number;
}

interface ErrorHandlerResult {
  shouldStopPolling: boolean;
  message: string;
  retryDelay: boolean | null;
  shouldShowToast: boolean;
}

export const handlePollingError = (error: unknown): ErrorHandlerResult => {
  const { ERROR_MESSAGES } = POLLING_CONFIG;

  const isPollingError = (err: unknown): err is PollingError => {
    return err instanceof Error && (
      ('response' in err && typeof (err as PollingError).response?.status === 'number') ||
      ('status' in err && typeof (err as PollingError).status === 'number')
    );
  };

  if (!isPollingError(error)) {
    return {
      shouldStopPolling: true,
      message: ERROR_MESSAGES.UNEXPECTED_ERROR,
      retryDelay: null,
      shouldShowToast: true
    };
  }

  const statusCode = error.response?.status ?? error.status;

  if (statusCode === 401) {
    return {
      shouldStopPolling: true,
      message: ERROR_MESSAGES.UNAUTHORIZED,
      retryDelay: null,
      shouldShowToast: true
    };
  }

  if (statusCode && (statusCode >= 500 || !statusCode)) {
    return {
      shouldStopPolling: false,
      message: ERROR_MESSAGES.SERVER_ERROR,
      retryDelay: true,
      shouldShowToast: true
    };
  }

  return {
    shouldStopPolling: true,
    message: ERROR_MESSAGES.GENERIC_ERROR,
    retryDelay: null,
    shouldShowToast: true
  };
};

export const usePolling = <T>(
  fetchFn: () => Promise<T>,
  config: PollingConfig<T> = {}
) => {
  const {
    interval = POLLING_CONFIG.INITIAL_INTERVAL,
    maxInterval = POLLING_CONFIG.MAX_INTERVAL,
    backoffMultiplier = POLLING_CONFIG.BACKOFF_MULTIPLIER,
    enabled = true,
    showToasts = true,
    retryOnVisibilityChange = true,
    maxRetries = POLLING_CONFIG.MAX_RETRIES,
    onSuccess,
    onError,
    onOfflineChange,
    minPollingInterval = interval,
    continuePollingOnSuccess = false,
    isComplete,
  } = config;

  const internalLastPollTimeRef = useRef(0);
  const lastPollTimeRef = config.lastPollTimeRef || internalLastPollTimeRef;

  const [isPolling, setIsPolling] = useState(enabled);
  const [isOffline, setIsOffline] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const isMounted = useRef(true);
  const retryCountRef = useRef(0);
  const currentIntervalRef = useRef(interval);
  const toastIdRef = useRef<string | null>(null);
  const isPollingInProgress = useRef(false);

  const clearErrorToast = useCallback(() => {
    if (toastIdRef.current) {
      toast.dismiss(toastIdRef.current);
      toastIdRef.current = null;
    }
  }, []);

  const updateOfflineStatus = useCallback((newStatus: boolean) => {
    if (isOffline !== newStatus) {
      setIsOffline(newStatus);
      onOfflineChange?.(newStatus);
    }
  }, [isOffline, onOfflineChange]);

  const resetPollingState = useCallback(() => {
    retryCountRef.current = 0;
    currentIntervalRef.current = interval;
    clearErrorToast();
  }, [interval, clearErrorToast]);

  const poll = useCallback(async (bypassTimeCheck = false) => {
    if (!isPolling || !isMounted.current || isPollingInProgress.current) return;

    const now = Date.now();
    const timeSinceLastPoll = now - lastPollTimeRef.current;
    const currentDelay = Math.max(currentIntervalRef.current, minPollingInterval);

    if (!bypassTimeCheck && timeSinceLastPoll < currentDelay) {
      timeoutRef.current = setTimeout(() => poll(), currentDelay - timeSinceLastPoll);
      return;
    }

    try {
      isPollingInProgress.current = true;
      const result = await fetchFn();
      lastPollTimeRef.current = now;

      retryCountRef.current = 0;
      currentIntervalRef.current = interval;
      updateOfflineStatus(false);
      clearErrorToast();

      const successResult = onSuccess?.(result);
      let shouldStopPolling = successResult === false;

      if (continuePollingOnSuccess && isComplete) {
        shouldStopPolling = isComplete(result);
      } else if (!continuePollingOnSuccess) {
        shouldStopPolling = true;
      }

      if (isMounted.current && isPolling && !shouldStopPolling) {
        timeoutRef.current = setTimeout(() => poll(), Math.max(interval, minPollingInterval));
      } else if (shouldStopPolling) {
        setIsPolling(false);
      }
    } catch (error) {
      console.error("Polling error:", error);
      onError?.(error);

      const { shouldStopPolling, message, retryDelay, shouldShowToast } = handlePollingError(error);

      if (shouldStopPolling || retryCountRef.current >= maxRetries) {
        setIsPolling(false);
        clearErrorToast();
        if (showToasts && shouldShowToast) {
          toast.error(message);
        }
        return;
      }

      if (retryDelay) {
        retryCountRef.current++;
        currentIntervalRef.current = Math.min(
          currentIntervalRef.current * backoffMultiplier,
          maxInterval
        );
      }

      updateOfflineStatus(true);
      if (showToasts && shouldShowToast) {
        toastIdRef.current = toast.error(message, { duration: Infinity });
      }

      if (isMounted.current && isPolling) {
        timeoutRef.current = setTimeout(() => poll(), Math.max(interval, minPollingInterval));
      }
    } finally {
      isPollingInProgress.current = false;
    }
  }, [
    fetchFn,
    interval,
    maxInterval,
    backoffMultiplier,
    maxRetries,
    isPolling,
    showToasts,
    updateOfflineStatus,
    clearErrorToast,
    onSuccess,
    onError,
    lastPollTimeRef,
    minPollingInterval,
    continuePollingOnSuccess,
    isComplete
  ]);

  const start = useCallback(() => {
    if (!isPolling && isMounted.current) {
      setIsPolling(true);
      resetPollingState();
      poll();
    }
  }, [isPolling, poll, resetPollingState]);

  const stop = useCallback(() => {
    setIsPolling(false);
    resetPollingState();
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [resetPollingState]);

  useEffect(() => {
    isMounted.current = true;

    if (enabled) {
      poll();
    }

    return () => {
      isMounted.current = false;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      clearErrorToast();
    };
  }, [enabled, poll, clearErrorToast]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && isPolling && retryOnVisibilityChange) {
        poll();
      }
    };

    if (retryOnVisibilityChange) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
    }

    return () => {
      if (retryOnVisibilityChange) {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
      }
    };
  }, [isPolling, poll, retryOnVisibilityChange]);

  return {
    start,
    stop,
    poll
  };
};
